import React, { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import FileUploadHandler from '../mycomponents/FileUploadHandler';
import { Input } from '../components/Input';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiFileCogOutline, mdiPlus, mdiAccountOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { arraysEqual } from '../utils/dataTools';
import { convertTimestampToDate } from '../utils/dateUtils';
import departements from '../utils/departements';
import PartnerNameById from './PartnerNameById';
import { Button } from '../components/Button';
import { Select } from './Select';
import { CardApplicantWorkExperience } from './CardApplicantWorkExperience/CardApplicantWorkExperience';
import { applicantSkillLevelOptions } from '../utils/StatusSkills';

const transformDepartements = (departements) => {
    return departements.map(departement => ({
        value: departement.num_dep.toString(),
        label: departement.dep_name,
        region_name: departement.region_name
    }));
};

const EditApplicant = ({ id, onUpdateApplicant }) => {
    const auth = useAuth();
    const [defDep, setDefDep] = useState();
    const [formData, setFormData] = useState({
        partner_id: '',
        status: '',
        resume: '',
        label: '',
        rate: 0,
        last_name: '',
        first_name: '',
        trigraph: '',
        birth_date: '',
        birth_place: '',
        residence: '',
        languages: [],
        skills: [],
        diplomas: []
    });

    const [editingMode, setEditingMode] = useState(false); // State to track editing mode

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch applicant details
                const responseApplicant = await api.get(`/applicant/${id}`);
                setFormData(responseApplicant.data);
                const match = departements.find(departement => departement.num_dep.toString() === responseApplicant.data.residence);
                setDefDep(match);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        onUpdateApplicant(formData);
    }, [formData, onUpdateApplicant]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            residence: value
        }));
    };

    const handleUploadSuccess = (data) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            resume: data
        }));

        console.log('Uploaded file data:', data);
    };

    const handleSubmit = async (event) => {
        event?.preventDefault();
        try {
            await api.put(`/applicant/${id}`, formData);
            console.log('Applicant updated successfully');
        } catch (error) {
            console.error('Error updating applicant:', error);
        }
    };

    const [skillsData, setSkillsData] = useState(formData.skills);
    const handleSaveSkills = (updatedSkills) => {
        if (!arraysEqual(skillsData, updatedSkills)) {
            setSkillsData(updatedSkills);
            setFormData(prevFormData => ({
                ...prevFormData,
                skills: updatedSkills
            }));
            console.log('Skills updated');
        }
    };

    const [diplomasData, setDiplomasData] = useState(formData.diplomas);
    const handleSaveDiplomas = (updatedDiplomas) => {
        if (!arraysEqual(diplomasData, updatedDiplomas)) {
            setDiplomasData(updatedDiplomas);
            setFormData(prevFormData => ({
                ...prevFormData,
                diplomas: updatedDiplomas
            }));
            console.log('Diplomas updated');
        }
    };

    const [languagesData, setLanguagesData] = useState(formData.languages);
    const handleSaveLanguages = (updatedLanguages) => {
        if (!arraysEqual(languagesData, updatedLanguages)) {
            setLanguagesData(updatedLanguages);
            setFormData(prevFormData => ({
                ...prevFormData,
                languages: updatedLanguages
            }));
            console.log('Languages updated');
        }
    };

    const [experiencesData, setExperiencesData] = useState(formData.workexperiences);
    const handleSaveExperiences = (updatedExperiences) => {
        if (!arraysEqual(experiencesData, updatedExperiences)) {
            setExperiencesData(updatedExperiences);
            setFormData(prevFormData => ({
                ...prevFormData,
                workexperiences: updatedExperiences
            }));
            console.log('Experiences updated');
        }
    };

    const setCollapsed = async () => {
        if (editingMode) {
            await handleSubmit();
        }
        setEditingMode(!editingMode);
    };

    return (
        <div className='full-width'>

            <div className="columns-container">
                <div className="columnL">
                    <div>
                        <div className='card-component applicant-main width-fix'>
                            <div className="card-header-alt">
                                <div>
                                    <Icon
                                        path={mdiAccountOutline}
                                        color="#CED4DD"
                                        size="20px" />
                                    <span className="card-title">Informations</span>
                                </div>
                                <div className="headr">
                                    <Button
                                        appearance="secondary"
                                        className="button-instance"
                                        hasAfterIcon={false}
                                        hasBeforeIcon={false}
                                        hasDropdownIndicator={false}
                                        intent="none"
                                        label={editingMode ? 'Sauver' : 'Editer'}
                                        size="small"
                                        stateProp="normal"
                                        type="default"
                                        customBeforeIcon={mdiAccountOutline}
                                        customAfterIcon={mdiPlus}
                                        onClick={setCollapsed}
                                    />
                                </div>
                            </div>
                            <form className='card-content-v-padding' onSubmit={handleSubmit}>
                                <div className="v-padding-fix">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            CV : 
                                            <FileUploadHandler onUploadSuccess={handleUploadSuccess} resumeData={formData.resume} editingMode={editingMode} />
                                        </div>
                                    </div>
                                    <div className="input-container-normal"></div>
                                    <div className="input-container-normal align-right">

                                    </div>
                                </div>
                                <div className="inputs-row inputs-row-padfix">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Titre :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="label"
                                                    value={formData.label}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.label}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Partenaire :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            <PartnerNameById id={formData.partner_id} type="partner" />
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            TJM :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="number"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode} />
                                            ) : formData.rate + "€ HT/jour"}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Nom :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.last_name}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Prénom :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.first_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Date de naissance :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="date"
                                                    name="birth_date"
                                                    value={formData.birth_date}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : convertTimestampToDate(formData.birth_date)}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Lieu de naissance :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="birth_place"
                                                    value={formData.birth_place}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.birth_place}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Residence :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Select
                                                    name="residence"
                                                    options={transformDepartements(departements)}
                                                    value={formData.residence}
                                                    onChange={handleSelectChange}
                                                />
                                            ) : (
                                                `${defDep?.dep_name} (${formData.residence})`
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div className="columnR">
                    <div>
                        <CardSkills
                            skills={formData.skills}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveSkills}
                            title="Compétences"
                            placeHolder="Nouvelle compétence"
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.diplomas}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveDiplomas}
                            title="Diplômes et certifications"
                            placeHolder="Nouveau diplôme"
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            hasLevelSelect={false}
                        />
                    </div>
                    <div>
                        <CardApplicantWorkExperience
                            experiences={formData.workexperiences}
                            iconPath={mdiBriefcaseCheckOutline}
                            saveExperiences={handleSaveExperiences}
                            title="Expériences professionnelles"
                            placeHolder="Nouvelle compétence"
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.languages}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveLanguages}
                            title="Langues"
                            placeHolder="Langue"
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditApplicant;
