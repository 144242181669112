// Home.js
import React, { useState } from 'react';
import MissionsList from '../mycomponents/MissionsList';
import { Button } from '../components/Button';
import { mdiPlus } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import EditMissionPage from './EditMission';

const ListMissionsPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = (applicationId) => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div className='list-rfp-container'>
      <div className='list-rfp-header'>
        <div className='list-rfp-header-content-l'>
          Missions
        </div>
        <div className='list-rfp-header-content-r'>
          <span onClick={() => openDrawer()} >
            <Button
              appearance="primary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={true}
              hasDropdownIndicator={false}
              intent="none"
              label="Nouvelle mission"
              size="medium-default"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiPlus}
              customAfterIcon={mdiPlus}
            />
          </span>
        </div>
      </div>
      <div>
        <MissionsList />
      </div>
      <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
          <EditMissionPage />
        </Drawer>
      </div>

    </div>
  );
}

export default ListMissionsPage;
