import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { CardClientHeader } from '../mycomponents/CardClientHeader';
import CardHeader from '../mycomponents/CardHeader';
import { CardRfpDates } from '../mycomponents/CardRfpDates';
import { CardRfpLocation } from '../mycomponents/CardRfpLocation';
import { CardRfpDesc } from '../mycomponents/CardRfpDesc';
import { CardRfpApplicants } from '../mycomponents/CardRfpApplicants';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiWeb, mdiFileCogOutline, mdiBriefcaseCheckOutline, mdiDomain } from '@mdi/js';
import { SkillLevelOptions } from '../utils/StatusSkills';
const EditMissionPage = () => {
    const auth = useAuth();
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    const { id } = useParams(); // Get the mission ID from URL params
    const [formData, setFormData] = useState({
        status: '',
        client_name: '',
        client_id: '',
        prescriber: '',
        mission_title: '',
        offer_type: '',
        partner_name: '',
        partner_id: '',
        applicant_name: '',
        applicant_id: '',
        start_date: '',
        end_date: '',
        opt_start_date: '',
        opt_end_date: '',
        renewable: '',
        location_primary: '',
        location_secondary: '',
        remote: '',
        rate: '',
        documents: '',
        contracts: '',
    });
   
    const [error, setError] = useState(null);
    const [partnerName, setPartnerName] = useState('');
    const [rfpData, setRfpData] = useState({});

    useEffect(() => {
        if (id) {
            const fetchMissionData = async () => {
                try {
                    const response = await api.get(`/mission/${id}`); // Fetch mission data by ID
                    setFormData(response.data); // Populate form fields with fetched mission data
                    getPartnerById(response.data.partner_id);
                    const rfpResponse = await api.get(`/rfp/${response.data.rfp_id}`);
                    setRfpData(rfpResponse.data);
                } catch (error) {
                    console.error('Error fetching mission data:', error);
                    setError(error.message);
                }
            };
            fetchMissionData();
        }
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        try {
            if (id) {
                await api.put(`/mission/${id}`, formData); // Update mission data using PUT method
                //console.log('Mission data updated successfully');
            } else {
                await api.post('/mission', formData); // Create new mission using POST method
                console.log('Mission created successfully');
            }
        } catch (error) {
            console.error('Error saving mission data:', error);
            setError(error.message);
        }
    };

    const getPartnerById = async (partner_id) => {
        try {
            const partnerResponse = await api.get(`/partner/${partner_id}`);
            setPartnerName(partnerResponse.data.name);
        }
        catch (error) {
            console.error('Error saving mission data:', error);
            setError(error.message);
        }
    }
    return (
        <div>
            <CardClientHeader rfp_title={`${formData.client_name} - ${partnerName} - ${formData.applicant_name}`} client_name={formData.client_name} client_ref="CLREF" eq_ref="EQREF" rfp_id={`${id}`} />
            {error && <div>Error: {error}</div>}

            <div className="columns-container">
                <div className="columnL">
                    <div className="card-component">
                        <CardHeader
                            context='editor'
                            editButton={false}
                            iconPath={mdiDomain}
                            title='Mission'
                        />
                        <div className="card-content">
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Nom de la mission :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {formData.mission_title}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Nom du client :</div>
                                    <div className='drawer-formfield-inactive'>
                                       {formData.client_name}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Marché :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {rfpData.client_mkt}
                                    </div>
                                </div>
                                
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Références contrat GE :</div>
                                    <div className='drawer-formfield-inactive'>
                                       {formData.status}
                                    </div>
                                </div>

                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Références BDC client :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {formData.client_id}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Ref. Equity :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {rfpData.eq_ref}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Ref. Client :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {rfpData.client_ref}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Offer Type:</div>
                                    <div className='drawer-formfield-inactive'>
                                        {formData.offer_type}
                                    </div>
                                </div>

                                <div className="input-container-normal">
                                    <div className='input-header-normal'>Montant estimé :</div>
                                    <div className='drawer-formfield-inactive'>
                                        {rfpData.amount}&nbsp;€ HT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CardRfpDates

                        start_date={formData.start_date}
                        end_date={formData.end_date}
                        opt_start_date={formData.opt_start_date}
                        opt_end_date={formData.opt_end_date}
                        isAdmin={false}
                        editButton={false}
                        context='mission'
                    />
                    <CardRfpLocation
                        location_1={formData.location_primary}
                        location_2={formData.location_secondary}
                        remote={formData.remote}

                        isAdmin={false}
                        editButton={false}
                        context='mission'
                    />

                </div>
                <div className="columnR">
                    <CardRfpApplicants rfp_id={formData.rfp_id} />
                    <CardRfpDesc
                        description={rfpData.full_desc}
                        summary={rfpData.summ_desc}
                        editing={false}
                        handleSubmit={handleSubmit}
                        isAdmin={false}
                        editButton={false}
                    />
                    <div>
                        <CardSkills
                            skills={rfpData.skills}
                            iconPath={mdiFileCogOutline}

                            title="Compétences"
                            placeHolder="Compétence"
                            editButton={false}
                            enabled={true}
                            collapsed={true}

                            SkillLevelOptions={SkillLevelOptions}
                            hasLevelSelect={true}
                            isAdmin={false}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={rfpData.languages}
                            iconPath={mdiWeb}

                            title="Langues"
                            placeHolder="Langue"
                            editButton={false}

                            SkillLevelOptions={SkillLevelOptions}
                            hasLevelSelect={true}
                            isAdmin={false}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.diplomas}
                            iconPath={mdiBriefcaseCheckOutline}

                            title="Diplômes et certifications demandés"
                            placeHolder="Certification ou diplôme"
                            editButton={false}

                            SkillLevelOptions={SkillLevelOptions}
                            hasLevelSelect={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMissionPage;
