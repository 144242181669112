import React from "react";
import { mdiArrowLeft } from '@mdi/js';
import { IconButton } from '../../components/IconButton';
//import { Button } from "../../components/Button";
import "./style.css";
import {
  useNavigate,
} from 'react-router-dom';

export const CardClientHeader = ({
  client_name = "client_name",
  rfp_title,
  client_ref,
  eq_ref,
  rfp_id,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const goToProposal = () => {
    navigate(`/proposal/${rfp_id}`);
  }
  return (
    <div className="cardrfptitle">
      <div className="header  ">
        <div onClick={handleClick}>
          <IconButton
            appearance="primary"
            hasDropdownIndicator={false}
            rounded={false}
            size="small"
            stateProp="normal"
            type="outline"
            customIcon={mdiArrowLeft}
          />
          <div>
            <div className="rfptitle">{rfp_title}</div>
           
          </div>
        </div>
        <div className="headr" onClick={goToProposal}>
          
        </div>
      </div>
    </div>
  );
};