import React, { useState, useEffect, useCallback, useRef } from 'react';
import "./style.css";

export const Select = ({ options, value, onChange, width = '100%', sortable = false, sort = false, version = 'default', isInvalid=false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc'); // Initialize sort order
    const [sortedOptions, setSortedOptions] = useState(options);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const dropdownRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState(() => {
        const match = options.find(option => option.value === value);
        return match || { value: '', label: 'Choisissez' }; // Default option
    });

    useEffect(() => {
        const match = options.find(option => option.value === value);
        if (match && match.value !== selectedOption?.value) {
            setSelectedOption(match);
        }
        
    }, [value, options, selectedOption?.value]);

    useEffect(() => {
        if (sort) {
            const sorted = [...options];
            sorted.sort((a, b) => {
                const labelA = a.label || ''; // Provide an empty string as fallback
                const labelB = b.label || ''; // Provide an empty string as fallback
                return sortOrder === 'asc' ? labelA.localeCompare(labelB) : labelB.localeCompare(labelA);
            });
            setSortedOptions(sorted);
        } else {
            setSortedOptions([...options]);
        }
    }, [options, sortOrder, sort]);
    

    const handleOptionClick = useCallback((option) => {
        if (!selectedOption || option.value !== selectedOption.value) {
            setSelectedOption(option);
            if (version === 'v2') {
                onChange(option); // Pass the full option object in v2
            } else {
                onChange(option.value); // Pass only the value in default behavior
            }
        }
        setIsOpen(false);
    }, [selectedOption, onChange, version]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleKeyDown = (event) => {
        if (!isOpen) return;
        switch(event.key) {
            case 'ArrowDown':
                setHighlightedIndex(prevIndex => (prevIndex + 1) % sortedOptions.length);
                break;
            case 'ArrowUp':
                setHighlightedIndex(prevIndex => (prevIndex - 1 + sortedOptions.length) % sortedOptions.length);
                break;
            case 'Enter':
                handleOptionClick(sortedOptions[highlightedIndex]);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (isOpen && highlightedIndex !== null && dropdownRef.current) {
            const listItems = dropdownRef.current.children;
            const listItem = listItems[highlightedIndex];
            listItem?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [highlightedIndex, isOpen]);

    return (
        <div className={`custom-select ${!selectedOption ? 'placeholder' : ''} ${isOpen ? 'select-active' : ''} ${isInvalid ? 'input-error' : ''}`}
             style={{ width, zIndex: isOpen ? 2 : 1 }}
             onKeyDown={handleKeyDown}
             tabIndex="0"
             ref={dropdownRef}>
            <div className={`select-selected ${isOpen ? 'select-arrow-active' : ''}`} onClick={toggleDropdown}>
                {!selectedOption ? 'Choose:' : selectedOption.label}
                {sortable && <button onClick={toggleSortOrder}>Sort</button>}
            </div>
            {isOpen && (
                <div className={`select-items ${isOpen ? '' : 'select-hide'}`}>
                    {sortedOptions.map((option, index) => (
                        <div key={option.value} onClick={() => handleOptionClick(option)}
                             className={highlightedIndex === index ? 'selected' : ''}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
