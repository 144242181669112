import React, { useState, useCallback } from 'react';
import { mdiPlus, mdiDeleteForeverOutline, mdiPencilOutline, mdiContentSaveOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { IconButton } from '../../components/IconButton';
import { arraysEqual } from '../../utils/dataTools';
import { convertTimestampToDate } from '../../utils/dateUtils';

export const CardApplicantWorkExperience = ({
  experiences = [],
  saveExperiences,
  iconPath,
  title,
  editButton = true,
  SkillLevelOptions = [],
  hasLevelSelect = true,
  handleSubmit,
  lCollapsed = true,
  context = 'editor',
}) => {

  const [isEditing, setIsEditing] = useState(lCollapsed);

  const handleChange = useCallback((index, name, value) => {
    if (index === null) {

    } else {
      const updatedExperiences = experiences.map((skill, idx) => idx === index ? { ...skill, [name]: value } : skill);
      if (!arraysEqual(experiences, updatedExperiences)) {
        saveExperiences(updatedExperiences);
      }
    }
  }, [experiences, saveExperiences]);

  const handleAddExperience = () => {
    const currentExperiences = Array.isArray(experiences) ? experiences : [];
    let updatedExperiences = [...currentExperiences];
    updatedExperiences.push({ title: '', company: '', startDate: '', endDate: '', description: '' });
    saveExperiences(updatedExperiences);
  };

  const handleDelete = (index) => {
    const updatedExperiences = experiences.filter((_, idx) => idx !== index);
    saveExperiences(updatedExperiences);
  };

  const toggleEdit = async () => {
    if (!isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  }

  return (
    <div className={context === 'editor' ? ('card-component') : ('drawer-rfp-form-bloc')}>
      {context === 'editor' ? (
        <div className="card-header">
          <div>
            <Icon
              path={iconPath}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{title}</span>
          </div>
          <div className="headr">
            {editButton && (
              <IconButton
                appearance="primary"
                hasDropdownIndicator={false}
                rounded={false}
                size="small"
                stateProp="normal"
                type="default"
                customIcon={!isEditing ? mdiContentSaveOutline : mdiPencilOutline}
                onClick={toggleEdit}
              />
            )
            }
          </div>
        </div>
      ) : (
        <div className='header-xsmall'>
          {title}
        </div>
      )}
      <div className={context === 'editor' ? ('card-content card-content-v-padding') : ('card-content card-padding-fix')}>
        <div className="card-languages-list">
          <ul>
            {Array.isArray(experiences) && experiences.map((skill, index) => (
              <li key={index}>
                {isEditing ? (
                  <div>
                    <div className="inputs-row inputs-row-padfix">
                      <div className="input-container-normal">
                        <div className='list-rfp-title'>
                          {skill.title}
                        </div>
                        <div className='drawer-formfield-inactive'>
                          {skill.company}
                        </div>
                      </div>
                    </div>
                    <div className="inputs-row">
                      <div className="input-container-normal">
                        <div className='input-header-normal'>
                          Date de début :
                        </div>
                        <div className='drawer-formfield-inactive'>
                          {convertTimestampToDate(skill.startDate)}
                        </div>
                      </div>
                      <div className="input-container-normal">
                        <div className='input-header-normal'>
                          Date de fin :
                        </div>
                        <div className='drawer-formfield-inactive'>
                          {convertTimestampToDate(skill.endDate)}
                        </div>
                      </div>
                    </div>
                    <div className="inputs-row">
                      <div className="input-container-normal">
                        <div className='input-header-normal'>
                          Description du poste :
                        </div>
                        <div className='drawer-formfield-inactive'>
                          {skill.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='card-languages-row-2col'>
                      <div className='card-languages-left'>
                        <div className='card-languages-flex-fix'>
                          <div className="inputs-row">
                            <div className="input-container-normal">
                              <div className='input-header-normal'>
                                Poste occupé :
                              </div>
                              <div className='drawer-formfield-inactive'>
                                <Input
                                  type="text"
                                  name="title"
                                  value={skill.title}
                                  onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="inputs-row">
                          <div className="input-container-normal">
                            <div className='input-header-normal'>
                              Entreprise :
                            </div>
                            <div className='drawer-formfield-inactive'>
                              <Input
                                type="text"
                                name="company"
                                value={skill.company}

                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="input-container-normal">
                            <div className='input-header-normal'>
                              Date de début :
                            </div>
                            <div className='drawer-formfield-inactive'>
                              <Input
                                type="date"
                                name="startDate"
                                value={skill.startDate}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="input-container-normal">
                            <div className='input-header-normal'>
                              Date de fin :
                            </div>
                            <div className='drawer-formfield-inactive'>
                              <Input
                                type="date"
                                name="endDate"
                                value={skill.endDate}
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inputs-row">

                          <div className="input-container-normal">
                            <div className='input-header-normal'>
                              Description du poste :
                            </div>
                            <div className="input-container-normal">
                              <textarea
                                rows={4}
                                cols={60}
                                className='textareaSK'
                                name="description"
                                value={skill.description}
                                placeholder="Description"
                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-languages-right'>
                        <span onClick={() => handleDelete(index)}>
                          <IconButton
                            appearance="primary"
                            hasDropdownIndicator={false}
                            rounded={false}
                            size="small"
                            stateProp="normal"
                            type="default"
                            customIcon={mdiDeleteForeverOutline}
                          />
                        </span>

                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {!isEditing && (
            <div className='card-languages-row'>
              <div className='card-languages-left'>
                <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Ajouter"
                  size="small"
                  stateProp="normal"
                  type="default"
                  customAfterIcon={mdiPlus}
                  onClick={handleAddExperience}
                  width='140px'
                />
              </div>
              <div className='card-languages-middle'>

              </div>
              <div className='card-languages-right'>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
