import PropTypes from "prop-types";
import React from "react";
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';
import "./style.css";

export const Alert = ({
  showIcon = true,
  showText = true,
  showTitle = true,
  text = "Text",
  title = "Title",
  intent,
  className,
}) => {
  // Mapping des icônes et des couleurs aux valeurs de intent
  const iconMap = {
    "warning": { icon: mdiAlertCircleOutline, color: "#FFA500" }, 
    "neutral": { icon: mdiInformationOutline, color: "#2B3C56" }, 
    "negative": { icon: mdiAlertCircleOutline, color: "#FF0000" }, 
    "positive": { icon: mdiCheckCircleOutline, color: "#008000" }, 
    "informative": { icon: mdiInformationOutline, color: "#2B3C56" },
  };

  return (
    <div className={`alert ${intent} ${className}`}>
      <div className="line" />
      {showIcon && (
        // Utilisation de l'icône et de la couleur correspondantes à la valeur de intent
        <Icon path={iconMap[intent].icon} className="mdi-information" color={iconMap[intent].color} />
      )}

      <div className="alert-content">
        {showTitle && (
          <div className="title">
            <div className="text-wrapper">{title}</div>
          </div>
        )}

        {showText && (
          <div className="text">
            <div className="div">{text}</div>
          </div>
        )}
      </div>
    </div>
  );
};

Alert.propTypes = {
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  showTitle: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  intent: PropTypes.oneOf(["warning", "neutral", "negative", "positive", "informative"]),
};


