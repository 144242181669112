import React, { useState, useCallback, useRef } from 'react';
import { mdiPlus, mdiDeleteOutline } from '@mdi/js';
import { Button } from '../../components/Button';
import { Status } from "../../components/Status";
import { Input } from '../../components/Input';
import { IconButton } from '../../components/IconButton';
import CardHeader from '../CardHeader';
import { Select } from '../Select';
import "./style.css";
import { arraysEqual } from '../../utils/dataTools';
import { getSkillColor, getSkillLabel } from '../../utils/StatusSkills';

export const CardSkills = ({
  skills = [],
  saveLanguages: saveSkills,
  iconPath,
  title,
  editButton = true,
  SkillLevelOptions = [],
  hasLevelSelect = true,
  handleSubmit,
  lCollapsed = true,
  context = 'editor',
}) => {
  const [isEditing, setIsEditing] = useState(lCollapsed);
  const initialSkillsRef = useRef(skills);

  const handleChange = useCallback((index, name, value) => {
    if (index === null) {
    } else {
      const updatedSkills = skills.map((skill, idx) => idx === index ? { ...skill, [name]: value } : skill);
      if (!arraysEqual(skills, updatedSkills)) {
        saveSkills(updatedSkills);
      }
    }
  }, [skills, saveSkills]);

  const handleAddSkill = (event) => {
    if (event) {
      event.preventDefault();  // Prevent form submission if the event exists
    }
  
    const currentSkills = Array.isArray(skills) ? skills : [];
    let updatedSkills = [...currentSkills];
    updatedSkills.push({ title: '', level: '' });
    saveSkills(updatedSkills);
  };
  const handleDelete = (index) => {
    const updatedSkills = skills.filter((_, idx) => idx !== index);
    saveSkills(updatedSkills);
  };

  const handleSkillLevelChange = useCallback((index, newValue) => {
    const updatedSkills = skills.map((skill, idx) => {
      if (idx === index && skill.level !== newValue) { // Only update if level actually changes
        return { ...skill, level: newValue };
      }
      return skill;
    });
    if (!arraysEqual(skills, updatedSkills)) { // Use deep comparison to prevent unnecessary updates
      saveSkills(updatedSkills);
    }
  }, [skills, saveSkills]);

  const handleDiscard = () => {
    saveSkills(initialSkillsRef.current);
    setIsEditing(!isEditing);
  };


  const toggleEdit = async () => {
    if (!isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  }


  return (
    <div className={context==='editor' ?('card-component'):('drawer-rfp-form-bloc')}>
      <CardHeader
      isEditing={isEditing}
      context={context}
      editButton={editButton}
      iconPath={iconPath}
      title={title}
      handleDiscard={handleDiscard}
      toggleEdit={toggleEdit}
      />
      <div className={context==='editor' ?('card-content card-content-v-padding'):('card-content card-padding-fix')}>
        <div className="card-languages-list">
          <ul>
            {Array.isArray(skills) && skills.map((skill, index) => (
              <li key={index}>
                {isEditing ? (
                  <div className='card-languages-row-inactive'>
                    <div className='card-languages-left vertical-centering'>
                    {hasLevelSelect && (
                      <Status
                        className="status-instance"
                        color={getSkillColor(skill.level)}
                        emphasis="strong"
                        label={getSkillLabel(skill.level)}
                        style={{ width: '100px' }}
                      />
                    )}
                    </div>
                    <div className='card-languages-left vertical-centering'>
                      <span className='card-languages-label'>{skill.title}</span>
                    </div>
                  </div>
                ) : (
                  <div className='card-languages-row'>
                    <div className='card-languages-left'>
                      <Input
                        type="text"
                        name="title"
                        onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                        value={skill.title}
                      />
                    </div>
                    <div className='card-languages-middle'>
                      {hasLevelSelect && (
                        <Select
                        options={SkillLevelOptions}
                    
                        value={skill.level}
                        onChange={(newValue) => handleSkillLevelChange(index, newValue)}
                      />
                      )}   
                    </div>
                    <div className='card-languages-right-icon-bare flex-fix'>
                      <span className='align-right'>
                        <IconButton
                          appearance="primary"
                          hasDropdownIndicator={false}
                          rounded={true}
                          size="medium-default"
                          stateProp="normal"
                          type="bare"
                          customIcon={mdiDeleteOutline}
                          onClick={() => handleDelete(index)}
                        />
                      </span>

                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {!isEditing && (
            <div className='card-languages-row'>
              <div className='card-languages-left'>
              <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Ajouter"
                  size="small"
                  stateProp="normal"
                  type="default"
                  customAfterIcon={mdiPlus}
                  onClick={(e) => handleAddSkill(e)}
                  width='140px'
                />
              </div>
              <div className='card-languages-middle'>
              
              </div>
              <div className='card-languages-right'>
                
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
