import React, { useState, useEffect } from "react";
import { mdiTextBoxOutline } from '@mdi/js';
import { Input } from "../../components/Input";
import CardHeader from '../CardHeader';
import MailtoLink from '../MailToLink';
import "./style.css";

export const CardPartnerMainContact = ({
  contact = {},  // Default empty object to hold all relevant contact properties
  handleSubmit,
  onContactChange,
  title,
  editButton = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [_contact, setContact] = useState(contact || {});
  
  useEffect(() => {
    setContact(contact || {});  // Ensure address is always an object
  }, [contact]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newContact = { ..._contact, [name.split('.')[1]]: value }; // Splitting 'contact.name' to get 'name'

    setContact(newContact);
    if (onContactChange) {
      onContactChange(newContact);  // Notify parent of changes
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();  // Trigger handleSubmit from parent
    }
    setIsEditing(!isEditing);
  };

  const handleDiscard = () => {
    setContact(contact || {});  // Reset to original contact, ensuring it's not null
    if (onContactChange) {
      onContactChange(contact);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context='editor'
        editButton={editButton}
        iconPath={mdiTextBoxOutline}
        title={title}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content v-padding-s">
        <div className="inputs-row v-padding-z">
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Nom :
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="text"
                  name="contact.name"
                  value={_contact.name || ''}
                  onChange={handleInputChange}
                />
              ) : _contact.name}
            </div>
          </div>
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Email :
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="email"
                  name="contact.email"
                  value={_contact.email || ''}
                  onChange={handleInputChange}
                />
              ) : (
                <div >
                  <MailtoLink label={_contact.email} mailto={`mailto:${_contact.email}`} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
