import React, { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';

const PartnerNameById = ({ id, type }) => {
  const { updateToken } = useAuth();
  useEffect(() => {
    // Call setupInterceptors to set up request interceptors with authentication information
    setupInterceptors(updateToken);
  }, [updateToken]);

  const [clientName, setClientName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  let uri = '';
  if (type === "partner") {
    uri = 'partner';
  } else {
    uri = 'client';
  }

  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const response = await api.get(`/${uri}/${id}`);
        setClientName(response.data.name);
      } catch (error) {
        console.error('Error fetching client name:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientName();
  }, [id, uri]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <span>
      {clientName}
    </span>
  );
};

export default PartnerNameById;
