import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import "./style.css";

export const IconButton = ({ type, appearance, size, hasDropdownIndicator, stateProp, rounded, customIcon, className, onClick }) => {
  const [state, dispatch] = useReducer(reducer, {
    type: type || "default",
    appearance: appearance || "primary",
    size: size || "large",
    hasDropdownIndicator: hasDropdownIndicator || false,
    state: stateProp || "normal",
    rounded: rounded || false,
  });

  return (
    <div
      className={`icon-button ${state.state} ${state.type} has-dropdown-indicator-${state.hasDropdownIndicator} rounded-${state.rounded} ${state.size} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={onClick}
    >
      <Icon
        path={customIcon} // Utilisation de customIcon s'il est défini
        size={1}
        className={`${state.size === "small" ? "class" : "dropdown-indicator"}`}
        color={state.state === "hover" ? "#1D283A" : "#3F587E"}
      />
      {state.hasDropdownIndicator && (
        <Icon
          path={mdiChevronDown}
          size={1}
          className="dropdown-indicator"
          color={state.state === "hover" ? "#1D283A" : "#3F587E"}
        />
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "normal",
      };
      default:
        return state;
  }
}

IconButton.propTypes = {
  type: PropTypes.oneOf(["ghost", "outline", "bare", "default"]),
  appearance: PropTypes.oneOf(["primary"]),
  size: PropTypes.oneOf(["large", "medium-default", "small"]),
  hasDropdownIndicator: PropTypes.bool,
  stateProp: PropTypes.oneOf(["disabled", "hover", "normal"]),
  rounded: PropTypes.bool,
  customIcon: PropTypes.string, 
};
