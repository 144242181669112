import React from "react";
import { mdiArrowLeft, mdiAccountOutline, mdiPlus } from '@mdi/js';
import { IconButton } from '../../components/IconButton';
import { Button } from "../../components/Button";
import "./style.css";
import {
  useNavigate,
} from 'react-router-dom';

export const CardRfpTitle = ({

  client_name = "client_name",
  rfp_title,
  client_ref,
  eq_ref,
  rfp_id,
  toggleEditAndUpdate,
  isEditing,
  updateStatus,
  finalize,
  status,
  isAdmin = false,
  roles=[],
  context='default',
  createMission,
  complete=false
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const goToProposal = () => {
    navigate(`/proposal/${rfp_id}`);
  }
  if (roles.includes('client')) {
    return(
      <div className="cardrfptitle">
    <div className="header">
      <div onClick={handleClick}>
        <IconButton
          appearance="primary"
          hasDropdownIndicator={false}
          rounded={false}
          size="small"
          stateProp="normal"
          type="outline"
          customIcon={mdiArrowLeft}
        />
        <div>
          <div className="rfptitle">{rfp_title}</div>
          <div>
            <span className="rfptitle_client_ref">{client_ref}</span>
            <span className="rfptitle_strong_large"> {client_name}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
    )
  }
  else{
    return (
      <div className="cardrfptitle">
        <div className="header">
          <div onClick={handleClick}>
            <IconButton
              appearance="primary"
              hasDropdownIndicator={false}
              rounded={false}
              size="small"
              stateProp="normal"
              type="outline"
              customIcon={mdiArrowLeft}
            />
            <div>
              <div className="rfptitle">{rfp_title}</div>
              <div>
                <span className="rfptitle_client_ref">{client_ref}</span>
                <span className="rfptitle_strong_large"> {client_name}</span>
              </div>
            </div>
          </div>
          <div className="headr" >
            {status === 0 && context==='default' && (
           <div className="header">
             <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="error"
              label="Examiner"
              size="medium-default"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
              onClick={() => updateStatus({ status: 1 })}
            />
            </div>
            )}
            {status === 1 && context==='default' && (
              <div className="header">
                <Button
                appearance="secondary"
                className="button-instance"
                hasAfterIcon={false}
                hasBeforeIcon={false}
                hasDropdownIndicator={false}
                intent="error"
                label="Diffuser"
                size="medium-default"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiAccountOutline}
                customAfterIcon={mdiPlus}
                onClick={() => updateStatus({ status: 2 })}
              />
              </div>
            )}
            {status === 2 && context==='default' && (
               <div className="header">
                {isAdmin &&(
                  <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="error"
                  label="Clôturer"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus({ status: 3 })}
                />
                )}
                
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={true}
                  hasBeforeIcon={true}
                  hasDropdownIndicator={false}
                  intent="positive"
                  label="Répondre à l’appel d’offres"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  onClick={goToProposal}
                />
              </div>
            )}
            {status === 3 && context==='default' && (
              <div className="header">
                <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="error"
                  label="Réouvrir"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus({ status: 0 })}
                />
              </div>
            )}
            {status === 4 && context === 'default' && !window.location.pathname.includes('/proposal/') && (
              <div className="header">
                <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="error"
                  label="Annuler la mission"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus({ status: 0 })}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={true}
                  hasBeforeIcon={true}
                  hasDropdownIndicator={false}
                  intent="positive"
                  label="Envoyer en mission"
                  size="medium-default"
                  stateProp={complete?("normal"):("disabled")}
                  type="default"
                  onClick={createMission}
                />
              </div>
            )}
            {status === 5 && context==='default' && (
              <div className="header">
                 <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Précédent"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(0)}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Continuer"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(2)}
                />
              </div>
            )}
            {status === 6 && context==='default' && (
              <div className="header">
                 <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Précédent"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(1)}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Continuer"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(3)}
                />
              </div>
            )}
            {status === 7 && context==='default' && (
              <div className="header">
                 <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Précédent"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(2)}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Envoyer la candidature"
                  width="180px"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={finalize}
                />
              </div>
            )}
            {status === 0 && context==='rc' && (
              <div className="header">
                 <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="error"
                  label="Annuler"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  width="153px"
                  onClick={() => updateStatus(0)}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Suivant"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  width="153px"
                  onClick={() => updateStatus(1)}
                />
              </div>
            )}
            {status === 1  && context==='rc' && (
              <div className="header">
                 <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Précédent"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  width="153px"
                  onClick={() => updateStatus(0)}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Envoyer la candidature"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  width="180px"
                  onClick={finalize}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

};