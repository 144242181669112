import Keycloak from 'keycloak-js'

var initOptions = {
    url: window.KEYCLOAK_URL,
    redirectUri: window.KEYCLOAK_REDIRECT_URI,
    realm: window.KEYCLOAK_REALM,
    clientId: window.KEYCLOAK_CLIENT_ID,
    KeycloakResponseType: 'code',
    pkceMethod: "S256",
    checkLoginIframe: false,
    silentCheckSsoRedirectUri:
      window.location.origin + "/silent-check-sso.html",
  };
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(initOptions, { onLoad: "login-required", pkceMethod: "S256" })

export default keycloak