/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Counter = ({ showDot = false, number = "00", color, emphasis, size, className }) => {
  return (
    <div className={`counter ${color} ${emphasis} ${className}`}>
      <div className="number">{number}</div>
    </div>
  );
};

Counter.propTypes = {
  showDot: PropTypes.bool,
  number: PropTypes.string,
  color: PropTypes.oneOf(["white", "stroke", "grey", "blue", "green", "orange", "brand", "red"]),
  emphasis: PropTypes.oneOf(["low", "high"]),
  size: PropTypes.oneOf(["medium"]),
};
