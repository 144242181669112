// App.js
import './styleguide.css';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SideNav } from './components/SideNav';
import { useEffect, useState } from 'react';

import { setupInterceptors } from './middlewares/api';
import { useAuth } from './middlewares/useAuth';

import CreateRfpPage from './pages/CreateRfp';
import CreateClientPage from './pages/CreateClient';
import CreatePartnerPage from './pages/CreatePartner';
import CreateApplicantForm from './pages/CreateApplicant';
import EditApplicantPage from './pages/EditApplicant';
import EditClientPage from './pages/EditClient';
import EditPartnerPage from './pages/EditPartner';
import EditRfpPage from './pages/EditRfp';
import ListApplicantsPage from './pages/ListApplicant';
import ListClientPage from './pages/ListClients';
import MyApplicationListPage from './pages/ListMyApplication';
import CompanyPage from './pages/CompanyPage';
import ListPartnersPage from './pages/ListPartners';
import ListRfpsPage from './pages/ListRfps';
import LandingPage from './pages/Landing'
import CreateProposal from './flows/CreateProposal';
import { SideNavItemUserInfo } from './components/SideNavItemUserInfo';
import { LogoEquity1 } from "./icons/LogoEquity1";
import ListMissionsPage from './pages/ListMissions';
import EditMissionPage from './pages/EditMission';
import { IconButton } from './components/IconButton';
import { mdiMessageAlertOutline } from '@mdi/js';
import FeedbackForm from './mycomponents/FeedbackForm';
import RetainCandidateFlow from './flows/retainCandidate';

function App() {
  const { isAuthenticated, username, name, acronym, userRoles, logout, redirect } = useAuth();

  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  
  if (!auth.initialized) {
    return <div className='center'>
      <LogoEquity1 className="logo-equity" />
    </div>;
  }
  if (!isAuthenticated) {
    return redirect();
  } else {
    console.log("Done IAM");
    console.log(userRoles);
  }

  const openFeedbackModal = (url, username, name) => {
    if (isModalOpen) {
      setIsModalOpen(false);
      return;
    }
    setIsModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <Router>
      <div className="container">
      <FeedbackForm
            open={isModalOpen}
            handleClose={closeFeedbackModal}
            name={name}
            username={username}
            currentUrl
        />
        <nav className="navigation">
          <div className="side-nav-container">
            <SideNav userRoles={userRoles} />
          </div>
          {isAuthenticated && (
            <div className="user-info-container">
              <SideNavItemUserInfo username={username} acronym={acronym} userRoles={userRoles} name={name} onClick={logout} />
            </div>
          )}
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/rfp" element={<ListRfpsPage />} />
            <Route path="/createRfp" element={<CreateRfpPage />} />
            <Route path="/rfp/:id" element={<EditRfpPage />} />
            <Route path="/clients" element={<ListClientPage />} />
            <Route path="/clients/:id" element={<EditClientPage />} />
            <Route path="/createClient" element={<CreateClientPage />} />
            <Route path="/proposal/:id" element={<CreateProposal />} />
            <Route path="/partners" element={<ListPartnersPage />} />
            <Route path="/partners/:id" element={<EditPartnerPage />} />
            <Route path="/createPartner" element={<CreatePartnerPage />} />
            <Route path="/createApplicant" element={<CreateApplicantForm />} />
            <Route path="/applicants" element={<ListApplicantsPage />} />
            <Route path="/applicants/:id" element={<EditApplicantPage />} />
            <Route path="/myapplications" element={<MyApplicationListPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path='/missions' element={<ListMissionsPage />} />
            <Route path='/missions/:id' element={<EditMissionPage />} />
            <Route path='/rc/:rfp_id/:application_id' element={<RetainCandidateFlow />} />
          </Routes>
          <div className="floating-button-div">
          <IconButton
            appearance="primary"
            hasDropdownIndicator={false}
            rounded={false}
            size="medium-default"
            stateProp="normal"
            type="default"
            customIcon={mdiMessageAlertOutline}
            onClick={() => openFeedbackModal(window.location.href, 'your-username', 'Your Name')}
          />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
