// Home.js
import React, { useState } from 'react';
import PartnerList from '../mycomponents/PartnerList';
import Drawer from '@mui/material/Drawer';
import {mdiPlus } from '@mdi/js';
import { Button } from '../components/Button';
import CreatePartnerPage from './CreatePartner';

const ListPartnersPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = (applicationId) => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  return (
    <div className='list-rfp-container'>
    <div className='list-rfp-header'>
      <div className='list-rfp-header-content-l'>
        Partenaires
      </div>
      <div className='list-rfp-header-content-r'>
      <Button
              appearance="primary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={true}
              hasDropdownIndicator={false}
              intent="none"
              label="Nouveau partenaire"
              size="medium-default"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiPlus}
              customAfterIcon={mdiPlus}
              onClick={() => openDrawer()}
            />
      </div>
    </div>
    <div>   
     <PartnerList />
  </div>
  <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
         <div><CreatePartnerPage /></div>
        </Drawer>
      </div>
  </div>
  );
}

export default ListPartnersPage;
