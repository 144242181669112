import React, { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Select } from '../mycomponents/Select';
import { Button } from "../components/Button";
import { mdiAccountOutline, mdiPlus } from '@mdi/js';
import { CategoryOptions, LegalStatusOptions } from '../utils/corpUtils'
import { CardPartnerBankInfo } from '../mycomponents/CardPartnerBankInfo';
import { CardPartnerAddress } from '../mycomponents/CardPartnerAddress';

const CreateClientPage = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const [formData, setFormData] = useState({
    partnerData: {
      name: '',
      vat_nbr: '',
      siren: '',
      rcs: '',
      legal_status: '',
      category: '',
      capital: 0,
      phone_number: '',
      web_site: '',
      address: '',
      billing_addr: '', // Change from object to string
      bank_details: '',
      rib: {
        bankName: '',
        accountnbr: '',
        iban: '',
        bic: '',
        key: '',
        bankid: ''
      }
    },
    userData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    }
  });
  
  const handleLegalStatusChange = (newValue) => {
    setFormData({ ...formData, partnerData: { ...formData.partnerData, legal_status: newValue } });
  };

  const handleCategoryChange = (newValue) => {
    setFormData({ ...formData, partnerData: { ...formData.partnerData, category: newValue } });
  };

  const [error, setError] = useState(null);
  const [createdClient, setCreatedClient] = useState(null);

  const handleInputChange = (event, isUserData = false) => {
    const { name, value } = event.target;
    if (isUserData) {
      setFormData({ ...formData, userData: { ...formData.userData, [name]: value } });
    } else {
      setFormData({ ...formData, partnerData: { ...formData.partnerData, [name]: value } });
    }
  };

  const handleAddressChange = (updatedAddress) => {
    if (updatedAddress !== formData.partnerData.address) {
        setFormData(prevFormData => ({
            ...prevFormData,
            partnerData: {
                ...prevFormData.partnerData,
                address: updatedAddress
            }
        }));
    }
};

const handleBillingAddressChange = (updatedAddress) => {
  if (updatedAddress !== formData.partnerData.billing_addr) {
      setFormData(prevFormData => ({
          ...prevFormData,
          partnerData: {
              ...prevFormData.partnerData,
              billing_addr: updatedAddress // Ensure this matches the expected type
          }
      }));
  }
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);

    try {
      const response = await api.post('/client', formData);
      setCreatedClient(response.data);
    } catch (error) {
      setError(error.message);
    } finally {

    }
  };

  return (
    <div className='drawer-container'>
      <div className="header drawer-title">
        <div className="rfptitle">Nouveau client</div>
        <div className="headr" >
          <Button
            appearance="primary"
            className="button-instance"
            hasAfterIcon={false}
            hasBeforeIcon={false}
            hasDropdownIndicator={false}
            intent="positive"
            label="Enregistrer"
            size="medium-default"
            stateProp="normal"
            type="default"
            customBeforeIcon={mdiAccountOutline}
            customAfterIcon={mdiPlus}
            onClick={handleSubmit}
          />
        </div>
      </div>
      {createdClient && (
        <div>
          <div>New Partner Created:</div>
          <pre>{JSON.stringify(createdClient, null, 2)}</pre>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {/* Input fields for partner properties */}
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Information
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Nom :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="name" name="name" value={formData.partnerData.name} onChange={handleInputChange} />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                RCS :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="rcs" name="rcs" value={formData.partnerData.rcs} onChange={handleInputChange} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Numero TVA :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="vat_nbr" name="vat_nbr" value={formData.partnerData.vat_nbr} onChange={handleInputChange} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Siren
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="siren" name="siren" value={formData.partnerData.siren} onChange={handleInputChange} />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Forme juridique :
              </div>
              <div className='input-content-normal'>
                <Select name="legal_status" value={formData.partnerData.legal_status} options={LegalStatusOptions} onChange={handleLegalStatusChange} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Categorie d'entreprises :
              </div>
              <div className='input-content-normal'>
                <Select name="category" value={formData.partnerData.category} options={CategoryOptions} onChange={handleCategoryChange} />
              </div>
            </div>
          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Contact principal
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Email :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="email" name="email" value={formData.userData.email} onChange={(event) => handleInputChange(event, true)} />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Prénom :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="firstName" name="firstName" value={formData.userData.firstName} onChange={(event) => handleInputChange(event, true)} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Nom :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="lastName" name="lastName" value={formData.userData.lastName} onChange={(event) => handleInputChange(event, true)} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Téléphone :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="phone" name="phone" value={formData.userData.phone} onChange={(event) => handleInputChange(event, true)} />
              </div>
            </div>
          </div>
        </div>
        {/* Add input fields for other partner properties */}
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Coordonnées
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Numéro de téléphone :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="phone" name="phone" value={formData.partnerData.phone} onChange={handleInputChange} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Site web :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="web_site" name="web_site" value={formData.partnerData.web_site} onChange={handleInputChange} />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Capital :
              </div>
              <div className='input-content-normal'>
                <Input type="text" id="capital" name="capital" value={formData.partnerData.capital} onChange={handleInputChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="full-width">
          <CardPartnerAddress
            context='create'
            edit={true}
            title="Adresse"
            address={formData.partnerData.address}
            handleSubmit={handleSubmit}
            onAddressChange={handleAddressChange}
            editButton={true}
          />
        </div>
        <div className="full-width">
          <CardPartnerAddress
            context='create'
            edit={true}
            title="Adresse de facturation"
            address={formData.partnerData.billing_addr}
            handleSubmit={handleSubmit}
            onAddressChange={handleBillingAddressChange}
            editButton={true}
          />
        </div>
        <div className="full-width">
          <CardPartnerBankInfo
            context='create'
            edit={true}
            bankInfo={formData.partnerData.rib}
            handleSubmit={handleSubmit}
            onBankInfoChange={(updatedBankInfo) =>
              setFormData(prevFormData => ({
                ...prevFormData,
                partnerData: {
                  ...prevFormData.partnerData,
                  rib: updatedBankInfo
                }
              }))
            }
            editButton={true}
          />
        </div>
      </form>
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default CreateClientPage;
