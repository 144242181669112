import { useKeycloak } from '@react-keycloak/web';

export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const updateToken = async (minValidity) => {
    if (!keycloak.authenticated) {
      return Promise.reject("Not authenticated");
    }
    try {
      await keycloak.updateToken(minValidity);
      return keycloak.token;
    } catch (error) {
      console.error("Error updating token:", error);
      throw error;
    }
  };

  var _isAuthenticated = false;
  var _userRoles, _username, _token, _given_name, _family_name, _name, _partner_id, _client_id,_tokenParsed;
  if (!initialized) {
    return { isAuthenticated: false, isLoading: true };
  }
  if (keycloak.authenticated) {

    _isAuthenticated = keycloak.authenticated;
    _userRoles = keycloak.realmAccess?.roles;
    _username = keycloak.tokenParsed.preferred_username;
    _given_name = keycloak.tokenParsed.given_name;
    _family_name = keycloak.tokenParsed.family_name;
    _name = keycloak.tokenParsed.name;
    _token = keycloak.token;
    _tokenParsed = keycloak.tokenParsed;

    // Vérifie les attributs personnalisés pour partner_id et _client_id
    _partner_id = keycloak.tokenParsed.partner_id ? keycloak.tokenParsed.partner_id : null;
    _client_id = keycloak.tokenParsed._client_id ? keycloak.tokenParsed._client_id : null;

    try {
      // Default roles to remove
      var substringsToRemove = [/default-roles-myge/g, /offline_access/g, /uma_authorization/g];

      // Map over the array and replace each substring
      var cleanedArray = _userRoles.map(string => {
        substringsToRemove.forEach(substring => {
          string = string.replace(substring, '');
        });
        return string;
      });
      _userRoles = cleanedArray;
    } catch (error) {
      console.error("Error processing the array:", error);
    }
  } else {
    _isAuthenticated = false;
    _userRoles = null;
    _username = null;
    _token = null;
    _given_name = null;
    _name = null;
    _family_name = null;
    _partner_id = null;
    _tokenParsed = null;
    _client_id = null;
  }

  const isAuthenticated = _isAuthenticated;
  const userRoles = _userRoles;
  const username = _username;
  const token = _token;
  const givenName = _given_name;
  const familyName = _family_name;
  const name = _name;
  const partner_id = _partner_id;
  const tokenParsed = _tokenParsed;
  const client_id = _client_id;

  var acronym = '';
  try {
    var matches = _name.match(/\b(\w)/g); // ['J', 'S', 'O', 'N']
    acronym = matches.join(''); // 'JSON'
  } catch (error) {
    console.error('Error generating acronym:', error);
    acronym = ''; // Default or error handling value for acronym
  }

  const logout = () => {
    keycloak.logout();
  };
  const redirect = () => {
    keycloak.login();
  }

  return { isAuthenticated, userRoles, username, token, givenName, familyName, name, client_id, partner_id, acronym, logout, updateToken, initialized, redirect, tokenParsed };
};
