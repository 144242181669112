import React, { useState, useEffect } from "react";
import { mdiTextBoxOutline } from '@mdi/js';
import { Input } from "../../components/Input";
import CardHeader from '../CardHeader';
import "./style.css";

export const CardPartnerAddress = ({
  address = {},  // Default empty object
  handleSubmit,
  onAddressChange,
  title,
  editButton = true,
  context = 'editor',
  edit = false,
}) => {
  const [isEditing, setIsEditing] = useState(edit);
  const [_address, setAddress] = useState(address || {});

  useEffect(() => {
    setAddress(address || {});  // Ensure address is always an object
  }, [address]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newAddress = { ..._address, [name]: value };

    setAddress(newAddress);
    if (onAddressChange) {
      onAddressChange(newAddress);  // Notify parent of changes
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();  // Trigger handleSubmit from parent
    }
    setIsEditing(!isEditing);
  };

  const handleDiscard = () => {
    setAddress(address || {});  // Reset to original address, ensuring it's not null
    if (onAddressChange) {
      onAddressChange(address);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className={context === 'editor' ? ('card-component') : ('drawer-rfp-form-bloc')}>
      <CardHeader
        isEditing={!isEditing}
        context={context}
        editButton={editButton}
        iconPath={mdiTextBoxOutline}
        title={title}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className={context === 'editor' ? ('card-content card-content-v-padding') : ('card-content card-padding-fix')}>
        <div className="inputs-row">
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Code postal :
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="text"
                  name="zipcode"
                  className='input-normal'
                  value={_address?.zipcode}
                  onChange={handleInputChange}
                />
              ) : _address?.zipcode}
            </div>
          </div>
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Ville :
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="text"
                  name="city"
                  className='input-normal'
                  value={_address?.city}
                  onChange={handleInputChange}
                />
              ) : _address?.city}
            </div>
          </div>
        </div>
        <div className="inputs-row">
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Adresse 1
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="text"
                  name="line1"
                  className='input-normal'
                  value={_address?.line1}
                  onChange={handleInputChange}
                />
              ) : _address?.line1}
            </div>
          </div>
        </div>
        <div className="inputs-row">
          <div className="input-container-normal">
            <div className='input-header-normal'>
              Adresse 2 :
            </div>
            <div className='drawer-formfield-inactive'>
              {isEditing ? (
                <Input
                  type="text"
                  name="line2"
                  className='input-normal'
                  value={_address?.line2}
                  onChange={handleInputChange}
                />
              ) : _address?.line2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
