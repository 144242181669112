 // Options for Select components
 export const OfferTypeOptions = [
    { label: 'Forfait', value: 'Forfait' },
    { label: 'AT', value: 'AT' },
  ];

  export const selectRemoteOptions = [
    { label: 'Partiel', value: 'Partiel' },
    { label: 'Complet', value: 'Complet' },
    { label: 'Non', value: 'Non' },
  ];