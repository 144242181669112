import PropTypes from "prop-types";
import React, { useState } from "react";
import { mdiClose } from '@mdi/js';
import { IconButton } from "../IconButton";
import "./style.css";

export const Tag = ({
  prefix = "label",
  showPrefix = true,
  label = "label",
  initialStates = "default",
  hasCloseButton,
  className,
  onClickClose,
}) => {
  const [states, setStates] = useState(initialStates);
  const [showCloseButton] = useState(hasCloseButton);

  return (
    <div
      className={`tag states-${states} has-close-button-${showCloseButton} ${className}`}
      onMouseLeave={() => setStates("default")}
      onMouseEnter={() => setStates("hover")}
    >
      {showPrefix && (
        <div className="prefix">
          <div className="text-wrapper">{prefix}</div>
          <div className="text-wrapper">:</div>
        </div>
      )}
      <div className="label">{label}</div>
      {showCloseButton && (
        <IconButton
          appearance="primary"
          hasDropdownIndicator={false}
          rounded={false}
          size="small"
          stateProp="normal"
          type="bare"
          customIcon={mdiClose}
          onClick={onClickClose}
        />
      )}
    </div>
  );
};

Tag.propTypes = {
  prefix: PropTypes.string,
  showPrefix: PropTypes.bool,
  label: PropTypes.string,
  initialStates: PropTypes.oneOf(["hover", "default"]),
  hasCloseButton: PropTypes.bool,
};
