// src/utils/dateUtils.js

export const convertTimestampToDate = (timestamp) => {
    if (!timestamp){
        return '';
    }
    try {
        const date = new Date(timestamp);
        return date.toLocaleDateString("fr-FR", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    } catch (error) {
        console.error('Error converting timestamp:', error);
        return '';
    }
};

export const countWorkdays = (startDate, endDate) => {
    // Copy start and end dates to avoid mutating the original objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Initialize count of workdays
    let workdays = 0;

    // Loop through each day between the start and end dates
    while (start <= end) {
        // Check if the day is a weekday (Monday to Friday)
        if (start.getDay() !== 0 && start.getDay() !== 6) {
            workdays++;
        }
        // Move to the next day
        start.setDate(start.getDate() + 1);
    }

    return workdays;
}

