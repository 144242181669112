import * as React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Status } from "../components/Status";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Tag } from '../components/Tag';
import { convertTimestampToDate } from '../utils/dateUtils';
import FilterModal from './FilterModal'; // Import the new modal
import { Button } from '../components/Button';
import { mdiFilterVariant, mdiMagnify, mdiMenuSwap } from '@mdi/js';
import Icon from '@mdi/react';
import TablePaginationActions from './TablePaginationActions'; // Ensure the import path is correct
import PartnerNameById from './PartnerNameById';

export default function MissionsList() {
    const navigate = useNavigate();
    const [missions, setMissions] = useState([]);
    const [clients, setClients] = useState([]);
    const [partners, setPartners] = useState([]);
    const [sortField, setSortField] = useState('Date_debut_CA');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filterText, setFilterText] = useState('');
    const [filters, setFilters] = useState({
        client: '',
        partner: '',
        status: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const auth = useAuth();
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    const statusOptions = useMemo(() => [
        { value: 0, color: "neutral", label: "A venir" },
        { value: 1, color: "positive", label: "En cours" },
        { value: 2, color: "warning", label: "Terminée" },
        { value: 3, color: "negative", label: "Fin Anticipée" },
    ], []);

    useEffect(() => {
        const fetchMissions = async () => {
            try {
                const response = await api.get(`/mission`);
                setMissions(response.data.items);

                // Extract unique clients and partners
                const uniqueClients = [...new Set(response.data.items.map(item => item.Client))].map(client => ({ id: client, name: client }));
                const uniquePartners = [...new Set(response.data.items.map(item => item.Partenaires))].map(partner => ({ id: partner, name: partner }));

                setClients(uniqueClients);
                setPartners(uniquePartners);
            } catch (error) {
                console.error('Error fetching missions:', error);
            }
        };
        fetchMissions();
    }, []);

    const handleFilterChange = useCallback((name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    const resetFilters = useCallback(() => {
        setFilters({
            client_name: '',
            partner: '',
            status: '',
        });
    }, []);

    const closeTag = (key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: ''
        }));
    }

    const filteredAndSortedMissions = useMemo(() => {
        return missions
            .filter(row => {
                const rowString = [
                    row.client_name,
                    row.Consultant,
                    row.Partenaires,
                    row.mission_title,
                    row.Bon_de_commande,
                    row.status,
                ].join(' ').toLowerCase();
    
                const matchesTextFilter = rowString.includes(filterText.toLowerCase());
                const matchesClientFilter = filters.client === '' || row.Client === filters.client;
                const matchesPartnerFilter = filters.partner === '' || row.Partenaires === filters.partner;
                const matchesStatusFilter = filters.status === '' || row.ETAT === filters.status; // Ensure 'status' is used
    
                return matchesTextFilter && matchesClientFilter && matchesPartnerFilter && matchesStatusFilter;
            })
            .sort((a, b) => {
                if (sortField && a[sortField] && b[sortField]) {
                    if (sortDirection === 'asc') {
                        return a[sortField].toString().localeCompare(b[sortField].toString(), undefined, { numeric: true });
                    } else {
                        return b[sortField].toString().localeCompare(a[sortField].toString(), undefined, { numeric: true });
                    }
                }
                return 0;
            });
    }, [missions, filterText, filters, sortField, sortDirection]);
    

    const paginatedMissions = useMemo(() => {
        return filteredAndSortedMissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredAndSortedMissions, page, rowsPerPage]);

    const getStatusColor = useCallback((status) => {
        const option = statusOptions.find(option => option.value === status);
        if (!option) {
            console.error(`No matching status option found for: ${status}`);
            return 'neutral';
        }
        return option.color;
    }, [statusOptions]);
    

    const getStatusLabel = useCallback((status) => {
        try {
            return statusOptions.find(option => option.value === status).label;
        } catch (e) {
            return 'Unknown';
        }
    }, [statusOptions]);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterOptions = [
        { key: 'status', label: 'Status', defaultLabel: 'Tous les statuts', options: statusOptions.map(option => ({ value: option.value.toString(), label: option.label })) },
        { key: 'client', label: 'Client', defaultLabel: 'Tous les clients', options: clients.map(client => ({ value: client.id, label: client.name })) },
        { key: 'partner', label: 'Partenaire', defaultLabel: 'Tous les partenaires', options: partners.map(partner => ({ value: partner.id, label: partner.name })) },
    ];

    const headerStyles = {};

    return (
        <div>
            <FilterModal
            open={modalOpen}
            handleClose={closeModal}
            filters={filters}
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            resetFilters={resetFilters}
        />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <Input
                                    type="text"
                                    placeholder="Rechercher"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                    customWidth="160px"
                                    iconBefore={mdiMagnify}
                                />
                            </TableCell>
                            <TableCell align="left" colSpan={7}>
                                {filterOptions.map((filterOption) => {
                                    const option = statusOptions.find(option => option.value.toString() === filters[filterOption.key]);
                                    return filters[filterOption.key] ? (
                                        <Tag
                                            key={filterOption.key}
                                            className="tag-instance"
                                            hasCloseButton={true}
                                            label={option ? option.label : filters[filterOption.key]}
                                            prefix={filterOption.label}
                                            states="default"
                                            onClickClose={e=>closeTag(filterOption.key)}
                                        />
                                    ) : null;
                                })}
                            </TableCell>

                            <TableCell align='right'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={false}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="none"
                                    label="Filtrer"
                                    size="small"
                                    stateProp="normal"
                                    type="default"
                                    customBeforeIcon={mdiFilterVariant}
                                    customAfterIcon={mdiFilterVariant}
                                    onClick={openModal}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ ...headerStyles }}>
                            <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '0px',  border:0, width:10 }}><Icon path={mdiMenuSwap} size={1} onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')} /></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Ref Equity</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Intitulé</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Client</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Ref Client</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Statut</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Partenaires</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Consultant</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Bon de commande</span></TableCell>
                            <TableCell align="left" onClick={() => setSortField('Date_debut_CA')}><span className='list-table-header'>Date début</span></TableCell>
                            <TableCell align="left" onClick={() => setSortField('Date_fin_CA')}><span className='list-table-header'>Date fin</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedMissions.map((row) => (
                            <TableRow
                                key={row.id}
                                hover={true}
                                onClick={() => navigate(`/missions/${row.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>

                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-eq_ref'>{row.eq_ref}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-title'>{row.mission_title}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <div className='cell-text-label'>{row.client_name}</div>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-eq_ref'>{row.client_ref}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <Status color={getStatusColor(row.status)} label={getStatusLabel(row.status)} emphasis="strong" />
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-content'>
                                        <PartnerNameById id={row.partner_id} type="partner" />
                                    </span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-content'>{row.applicant_name}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-content'>{row.Bon_de_commande}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-content'>{convertTimestampToDate(row.start_date)}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}>
                                    <span className='list-rfp-content'>{convertTimestampToDate(row.end_date)}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    className="custom-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAndSortedMissions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Lignes par page :"
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count, page }) => {
                        const totalPages = Math.ceil(count / rowsPerPage);
                        return `${page + 1} / ${totalPages}`;
                    }}
                />
            </TableContainer>
        </div>
    );
}
