  import axios from 'axios';

  const api = axios.create({
    baseURL: window.API_ENDPOINT,
  });

  export const setupInterceptors = (updateToken) => {
    
    let isRefreshing = false;
    let refreshSubscribers = [];

    const subscribeTokenRefresh = (cb) => {
      refreshSubscribers.push(cb);
    };

    const onTokenRefreshed = (token) => {
      refreshSubscribers.forEach((cb) => cb(token));
    };

    // Add request interceptor to set the Authorization header using the token retrieved from updateToken
    api.interceptors.request.use(async (config) => {
      try {
        // This function assumes updateToken resolves with the current valid token or refreshes it if necessary
        const token = await updateToken(0); // Use a small threshold like 0 to ensure freshness for demonstration
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } catch (error) {
        console.error('Failed to update token for request:', error);
        throw error; // You might want to handle this differently depending on your error handling policy
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    // Define interceptor here to use it in the cleanup function
    const interceptor = api.interceptors.response.use(
      response => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          if (!isRefreshing) {
            isRefreshing = true;
            originalRequest._retry = true;

            try {
              const refreshedToken = await updateToken(5); // Force a refresh if close to expiration or expired
              api.defaults.headers.common['Authorization'] = `Bearer ${refreshedToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${refreshedToken}`;
              onTokenRefreshed(refreshedToken);
              return api(originalRequest);
            } catch (refreshError) {
              console.error('Token refresh error:', refreshError);
              return Promise.reject(refreshError);
            } finally {
              isRefreshing = false;
            }
          } else {
            return new Promise((resolve) => {
              subscribeTokenRefresh(token => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                resolve(api(originalRequest));
              });
            });
          }
        }
        return Promise.reject(error);
      }
    );

    // Return a cleanup function that ejects the interceptor when no longer needed
    return () => {
      api.interceptors.response.eject(interceptor);
    };
  };

  export default api;
