import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CardRfpTitle } from '../mycomponents/CardRfpTitle';
import { CardMissionInfo } from '../mycomponents/CardMissionInfo';
import { CardRfpDates } from '../mycomponents/CardRfpDates';
import { CardRfpLocation } from '../mycomponents/CardRfpLocation';
import { CardRfpDesc } from '../mycomponents/CardRfpDesc';
import { CardRfpApplicants } from '../mycomponents/CardRfpApplicants';
import { CardCheckList } from '../mycomponents/CardCheckList';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiWeb, mdiFileCogOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { arraysEqual } from '../utils/dataTools';
import { countWorkdays } from '../utils/dateUtils';
import { SkillLevelOptions } from '../utils/StatusSkills';
import { useNavigate } from 'react-router-dom';

const EditRfpPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        id: '',
        id_client: '',
        status: '',
        client_ref: '',
        owner: '',
        rfp_title: '',
        max_proposals: 0,
        offer_type: '',
        price_model: '',
        amount: 0,
        rfp_last_date: '',
        rfp_start_date: '',
        rfp_end_date: '',
        rfp_opt_start_date: '',
        rfp_opt_end_date: '',
        renewable: false,
        location_primary: '',
        location_secondary: '',
        remote: '',
        line_of_business: '',
        summ_desc: '',
        full_desc: '',
        deliverables: [],
        seniority: '',
        level: '',
        skills: [],
        languages: [],
        tasks: [
            { label: 'Task 1', completed: false },
            { label: 'Task 2', completed: false },
        ],
    });

    const [tasks, setTasks] = useState([
        { label: 'Première chose à faire.', completed: false },
        { label: 'Encore une petite chose.', completed: false },
        { label: 'La dernière touche.', completed: false },
    ]);
    const [editing, setEditing] = useState(false);
    const [clientName, setClientName] = useState('');
    const [loading, setLoading] = useState(true);
    const { userRoles } = useAuth();

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        if (auth.initialized) {
            const fetchRfpDetails = async () => {
                try {
                    setLoading(true);
                    const response = await api.get(`/rfp/${id}`);
                    setFormData(response.data);
                } catch (error) {
                    console.error('Error fetching RFP details:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchRfpDetails();

            if (auth.initialized && formData.id_client) {
                fetchClientName(formData.id_client);
            }

            if (formData.status === '3') {
                setEditing(false);
            }
        }
    }, [auth.initialized, id, formData.id_client, formData.status]);

    const fetchClientName = async (client_id) => {
        try {
            const response = await api.get(`/client/${client_id}`);
            setClientName(response.data.name);
        } catch (error) {
            console.error('Error fetching client name:', error);
        }
    };

    const handleSubmit = async (event) => {
        event?.preventDefault();
        try {
            await api.put(`/rfp/${id}`, formData)
                .then(() => console.log('RFP updated successfully', formData));
        } catch (error) {
            console.error('Error updating RFP:', error);
        }
    };

    const updateStatus = async (options) => {
        try {
            console.log('Before updating status:', formData.status);
    
            setFormData((prevFormData) => {
                const newFormData = {
                    ...prevFormData,
                    status: options.status,
                };
                console.log('New FormData in updateStatus:', newFormData.status);
                return newFormData;
            });
    
            // Wait for the state to update before proceeding
            await new Promise((resolve) => setTimeout(resolve, 0));
    
            console.log('After updating status:', formData.status); // This may still log the old value due to async state updates
            updateRfp({ ...formData, status: options.status }); // Ensure the updated state is used here
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    
    const updateRfp = useCallback(async (formDataToUpdate) => {
        try {
            const response = await api.put(`/rfp/${id}`, formDataToUpdate);
            console.log('RFP Update Response:', response.data);
        } catch (error) {
            console.error('Error updating RFP:', error);
        }
    }, [id]);
    
    useEffect(() => {
        if (formData.status === 99) {
            console.log('Detected status update to 99, calling updateRfp');
            updateRfp(formData);
        }
    }, [formData, updateRfp]);
    
    const setCollapsed = async () => {
        if (editing) {
            await updateRfp(formData);
        }
        setEditing(!editing);
    };

    const toggleEditAndUpdate = async () => {
        if (editing) {
            await updateRfp(formData);
        }
        setEditing(!editing);
    };

    const handleLocUpdates = (updatedLocations) => {
        setFormData(formData => ({
            ...formData,
            location_primary: updatedLocations.location_1,
            location_secondary: updatedLocations.location_2,
            remote: updatedLocations.remote
        }));
    };

    const handleDatesUpdates = (updatedDates) => {
        setFormData(formData => ({
            ...formData,
            rfp_last_date: updatedDates.last_date,
            rfp_start_date: updatedDates.start_date,
            rfp_end_date: updatedDates.end_date,
            rfp_opt_start_date: updatedDates.opt_start_date,
            rfp_opt_end_date: updatedDates.opt_end_date
        }));
    };

    const onMissionInfoChange = (updatedMissionInfo) => {
        setFormData(formData => ({
            ...formData,
            rfp_title: updatedMissionInfo.rfp_title,
            market: updatedMissionInfo.market,
            amount: updatedMissionInfo.amount,
            offer_type: updatedMissionInfo.offerType,
            client_ref: updatedMissionInfo.client_ref
        }));
    };

    const handleDescUpdates = (updatedDescriptions) => {
        setFormData(formData => ({
            ...formData,
            summ_desc: updatedDescriptions.summary,
            full_desc: updatedDescriptions.description,
        }));
    };

    const [skillsData, setSkillsData] = useState(formData.skills);
    const handleSaveskills = (updatedSkills) => {
        if (!arraysEqual(skillsData, updatedSkills)) {
            setSkillsData(updatedSkills);
            setFormData(prevFormData => ({
                ...prevFormData,
                skills: updatedSkills
            }));
            console.log('Skills updated');
        }
    };

    const [languagesData, setLanguagesData] = useState(formData.languages);
    const handleSaveLanguages = (updatedLanguages) => {
        if (!arraysEqual(languagesData, updatedLanguages)) {
            setLanguagesData(updatedLanguages);
            setFormData(prevFormData => ({
                ...prevFormData,
                languages: updatedLanguages
            }));
            console.log('Languages updated');
        }
    };

    const [diplomasData, setDiplomasData] = useState(formData.diplomas);
    const handleSaveDiplomas = (updatedDiplomas) => {
        if (!arraysEqual(diplomasData, updatedDiplomas)) {
            setDiplomasData(updatedDiplomas);
            setFormData(prevFormData => ({
                ...prevFormData,
                diplomas: updatedDiplomas
            }));
            console.log('Diplomas updated');
        }
    };

    const [allTasksCompleted, setAllTasksCompleted] = useState(false);

    const isAdmin = userRoles.includes('admin');

    useEffect(() => {
        if (formData.tasks) {
            setAllTasksCompleted(formData.tasks.every(task => task.completed));
        }
    }, [formData]);

    if (loading) {
        return null; // Add a loading indicator here if needed
    }

    const handleTaskChange = (updatedTasks) => {
        setTasks(updatedTasks);
        setAllTasksCompleted(updatedTasks.every(task => task.completed));
    };

    const createMission = async () => {
    try {
        const response = await api.get(`/rfp/selectApplication/${id}`);
        console.log('Application Data:', response.data);

        if (response.data && response.data.length > 0) {
            const applicationData = response.data[0];

            if (!applicationData.id) {
                throw new Error('Application ID is missing in response data');
            }

            // Application ID is available, proceed with mission creation
            const stateData = collectState(applicationData);
            const result = await api.post(`/mission`, stateData);

            await updateStatus({ status: 99 });

            navigate(`/missions/${result.data.id}`);
        } else {
            console.warn(`No application data found for ID: ${id}`);
        }
    } catch (error) {
        console.error('Error creating mission:', error);
    }
};

const collectState = (applicationData) => {
    return {
        application_id: applicationData.id,
        rfp_id: id,
        rfp: formData,
        application: applicationData,
        status: 0,
        client_name: clientName,
        client_id: formData.id_client,
        prescriber: formData.owner,
        mission_title: formData.rfp_title,
        offer_type: formData.offer_type,
        partner_name: '',
        partner_id: applicationData.partner_id,
        applicant_name: `${applicationData.first_name} ${applicationData.last_name}`,
        applicant_id: applicationData.applicant_id,
        start_date: formData.rfp_start_date,
        end_date: formData.rfp_end_date,
        opt_start_date: formData.rfp_opt_start_date,
        opt_end_date: formData.rfp_opt_end_date,
        renewable: 1,
        location_primary: formData.location_primary,
        location_secondary: formData.location_secondary,
        remote: formData.remote,
        rate: '',
        documents: {},
        contracts: {},
        client_ref: formData.client_ref,
        client_mkt: formData.client_mkt,
        eq_ref: formData.eq_ref,
    };
};

    return (
        <div>
            <CardRfpTitle
                rfp_title={formData.rfp_title}
                client_name={clientName}
                client_ref={formData.client_ref}
                eq_ref={formData.eq_ref}
                rfp_id={`${id}`}
                toggleEditAndUpdate={toggleEditAndUpdate}
                isEditing={editing}
                updateStatus={updateStatus}
                status={formData.status}
                isAdmin={isAdmin}
                roles={userRoles}
                complete={allTasksCompleted}
                createMission={createMission}
            />
            {formData.status === 4 ? (
                <div className="columns-container">
                    <div className="columnL-alt">
                        <CardMissionInfo
                            client_name={clientName}
                            client_id={formData.id_client}
                            market={formData.client_mkt}
                            rfp_title={formData.rfp_title}
                            offerType={formData.offer_type}
                            amount={formData.amount}
                            eq_ref={formData.eq_ref}
                            client_ref={formData.client_ref}
                            onMissionInfoChange={onMissionInfoChange}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                            createMission={createMission}
                        />
                        <CardRfpDates
                            last_date={formData.rfp_last_date}
                            start_date={formData.rfp_start_date}
                            end_date={formData.rfp_end_date}
                            opt_start_date={formData.rfp_opt_start_date}
                            opt_end_date={formData.rfp_opt_end_date}
                            closing={countWorkdays(Date.now(), formData.rfp_last_date)}
                            onDatesUpdated={handleDatesUpdates}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <CardRfpLocation
                            location_1={formData.location_primary}
                            location_2={formData.location_secondary}
                            remote={formData.remote}
                            onLocationsChange={handleLocUpdates}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <CardRfpApplicants rfp_id={id} />
                        <CardRfpDesc
                            description={formData.full_desc}
                            summary={formData.summ_desc}
                            onDescriptionChange={handleDescUpdates}
                            editing={editing}
                            setEditing={setCollapsed}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <div>
                            <CardSkills
                                skills={formData.skills}
                                iconPath={mdiFileCogOutline}
                                saveLanguages={handleSaveskills}
                                title="Compétences"
                                placeHolder="Compétence"
                                editButton={isAdmin}
                                enabled={true}
                                collapsed={!editing}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                                isAdmin={isAdmin}
                            />
                        </div>
                        <div>
                            <CardSkills
                                skills={formData.languages}
                                iconPath={mdiWeb}
                                saveLanguages={handleSaveLanguages}
                                title="Langues"
                                placeHolder="Langue"
                                editButton={isAdmin}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                                isAdmin={isAdmin}
                            />
                        </div>
                        <div>
                            <CardSkills
                                skills={formData.diplomas}
                                iconPath={mdiBriefcaseCheckOutline}
                                saveLanguages={handleSaveDiplomas}
                                title="Diplômes et certifications demandés"
                                placeHolder="Certification ou diplôme"
                                editButton={isAdmin}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                            />
                        </div>
                    </div>
                    <div className="columnR-alt">
                        <CardCheckList tasks={tasks} onTaskChange={handleTaskChange} />
                    </div>
                </div>
            ) : (
                <div className="columns-container">
                    <div className="columnL">
                        <CardMissionInfo
                            client_name={clientName}
                            client_id={formData.id_client}
                            market={formData.client_mkt}
                            rfp_title={formData.rfp_title}
                            offerType={formData.offer_type}
                            amount={formData.amount}
                            eq_ref={formData.eq_ref}
                            client_ref={formData.client_ref}
                            onMissionInfoChange={onMissionInfoChange}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <CardRfpDates
                            last_date={formData.rfp_last_date}
                            start_date={formData.rfp_start_date}
                            end_date={formData.rfp_end_date}
                            opt_start_date={formData.rfp_opt_start_date}
                            opt_end_date={formData.rfp_opt_end_date}
                            closing={countWorkdays(Date.now(), formData.rfp_last_date)}
                            onDatesUpdated={handleDatesUpdates}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <CardRfpLocation
                            location_1={formData.location_primary}
                            location_2={formData.location_secondary}
                            remote={formData.remote}
                            onLocationsChange={handleLocUpdates}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                    </div>
                    <div className="columnR">
                        <CardRfpApplicants rfp_id={id} />
                        <CardRfpDesc
                            description={formData.full_desc}
                            summary={formData.summ_desc}
                            onDescriptionChange={handleDescUpdates}
                            editing={editing}
                            setEditing={setCollapsed}
                            handleSubmit={handleSubmit}
                            isAdmin={isAdmin}
                            editButton={isAdmin}
                        />
                        <div>
                            <CardSkills
                                skills={formData.skills}
                                iconPath={mdiFileCogOutline}
                                saveLanguages={handleSaveskills}
                                title="Compétences"
                                placeHolder="Compétence"
                                editButton={isAdmin}
                                enabled={true}
                                collapsed={!editing}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                                isAdmin={isAdmin}
                            />
                        </div>
                        <div>
                            <CardSkills
                                skills={formData.languages}
                                iconPath={mdiWeb}
                                saveLanguages={handleSaveLanguages}
                                title="Langues"
                                placeHolder="Langue"
                                editButton={isAdmin}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                                isAdmin={isAdmin}
                            />
                        </div>
                        <div>
                            <CardSkills
                                skills={formData.diplomas}
                                iconPath={mdiBriefcaseCheckOutline}
                                saveLanguages={handleSaveDiplomas}
                                title="Diplômes et certifications demandés"
                                placeHolder="Certification ou diplôme"
                                editButton={isAdmin}
                                handleSubmit={handleSubmit}
                                SkillLevelOptions={SkillLevelOptions}
                                hasLevelSelect={true}
                            />
                        </div>

                    </div>
                </div>
            )}

            <div>&nbsp;</div>
        </div>
    );
};

export default EditRfpPage;
