// Home.js
import React, { useState } from 'react';
import { CardMyApplications } from '../mycomponents/CardMyApplications';
import Drawer from '@mui/material/Drawer';

const MyApplicationListPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  
  return (
    <div className='list-rfp-container'>
    <div className='list-rfp-header'>
      <div className='list-rfp-header-content-l'>
        Mes candidatures
      </div>
      <div className='list-rfp-header-content-r'>
        
      </div>
    </div>
    <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>   
     <CardMyApplications />
  </div>
  <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
        </Drawer>
      </div>
  </div>
  );
}

export default MyApplicationListPage;
