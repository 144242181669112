import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import {useNavigate} from 'react-router-dom';
import { convertTimestampToDate } from '../utils/dateUtils';

export default function BasicTable() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [rfpStatusCounts, setRfpStatusCounts] = useState([]);

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await api.get(`/client`);
                if (response.status === 200 && response.data.items) {
                    setClients(response.data.items);
                } else {
                    console.error('Unexpected response structure:', response);
                    // Optionally set an error state here to show an error message on the UI
                }

                const rfpStatusResponse = await api.get(`/rfp/statusCounts`);
                //console.log(rfpStatusResponse.data);  // Check what's actually being received here
                if (Array.isArray(rfpStatusResponse.data)) {
                    setRfpStatusCounts(rfpStatusResponse.data);
                } else {
                    console.error('Received data is not an array:', rfpStatusResponse.data);
                    setRfpStatusCounts([]); // Reset to default empty array if not array
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                // Optionally set an error state here to show an error message on the UI
            }
        };
        fetchClients();
    }, []);

    const headerStyles = {
       
    };
    
    return (

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ ...headerStyles }}>
                        <TableCell align="left"><span className='list-table-header'>Nom</span></TableCell>
                        <TableCell align="center"><span className='list-table-header'>Brouillons</span></TableCell>
                        <TableCell align="center"><span className='list-table-header'>AO à valider</span></TableCell>
                        <TableCell align="center"><span className='list-table-header'>AO en cours</span></TableCell>
                        <TableCell align="center"><span className='list-table-header'>AO terminés</span></TableCell>
                        <TableCell align="center"><span className='list-table-header'>Candidatures à examiner</span></TableCell>
                        <TableCell align="left"><span className='list-table-header'>Contact Principal</span></TableCell>
                        <TableCell align="right"><span className='list-table-header'>Date de création</span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client) => {
                        const clientStatusCounts = rfpStatusCounts?.filter(rfp => rfp.id_client === client.id) || [];
                        const draftCount = clientStatusCounts.find(rfp => rfp.status === 0)?.statusCount || 0;
                        const reviewCount = clientStatusCounts.find(rfp => rfp.status === 1)?.statusCount || 0;
                        const activeCount = clientStatusCounts.find(rfp => rfp.status === 2)?.statusCount || 0;
                        const closedCount = clientStatusCounts.find(rfp => rfp.status === 3)?.statusCount || 0;
                        const totalApplications = clientStatusCounts.reduce((acc, curr) => acc + parseInt(curr.applicationCount || 0), 0);

                        return (
                            <TableRow key={client.id} hover={true}
                            onClick={() => navigate(`/clients/${client.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{
                                    cursor: 'pointer',
                                    
                                 }}>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className='list-rfp-title'>{client.name}</span></TableCell>
                                <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className={`list-rfp-content ${draftCount > 0 ? 'counter number' : ''}`}>{draftCount}</span></TableCell>
                                <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className={`list-rfp-content ${reviewCount > 0 ? 'counter number' : ''}`}>{reviewCount}</span></TableCell>
                                <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className={`list-rfp-content ${activeCount > 0 ? 'counter number' : ''}`}>{activeCount}</span></TableCell>
                                <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className={`list-rfp-content ${closedCount > 0 ? 'counter number' : ''}`}>{closedCount}</span></TableCell>
                                <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className={`list-rfp-content ${totalApplications > 0 ? 'counter number' : ''}`}>{totalApplications}</span></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className='list-rfp-content'>{client?.contact?.name}</span></TableCell>
                                <TableCell align="right" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className='list-rfp-content'>{convertTimestampToDate(client.createdAt)}</span></TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
