export const statusOptions = [
    { color: "brand", label: "Examen Equity" },
    { color: "informative", label: "Examen Client" },
    { color: "negative", label: "Rejeté Equity" },
    { color: "negative", label: "Rejeté client" },
    { color: "positive", label: "Retenu" }
];

export const getStatusColor = (index) => {
    try {
        return statusOptions[index].color;
    } catch (e) {
        return 'neutral';
    }
};

export const getStatusLabel = (index) => {
    try {
        return statusOptions[index].label;
    } catch (e) {
        return 'N/A';
    }
};