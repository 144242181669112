
import React, { useState, useEffect } from 'react';
import { Input } from '../../components/Input';

export const CardRfpApplicationProposal = ({
  contractDetails = { partner_margin_rate: '0', client_margin_rate: '0', partner_margin_amount: '0', client_margin_amount: '0', comment: '', date_dispo: '', rate: ''},
  onSaveContractDetails,
  rate = '000',
  editing = false,
  isAdmin,
  context='default'
}) => {

  const [localComment, setLocalComment] = useState(contractDetails.comment || '');
  const [localDateDispo, setLocalDateDispo] = useState(contractDetails.date_dispo || '');
  const [currentRate, setCurrentRate] = useState(parseFloat(rate));

  useEffect(() => {
    onSaveContractDetails({
      ...contractDetails,
      rate: currentRate.toString(),
    });
  }, [contractDetails, onSaveContractDetails, currentRate]);

  useEffect(() => {
    onSaveContractDetails(contractDetails);
  }, [contractDetails, onSaveContractDetails]);

  // Recalculate margin rates and amounts when currentRate changes
  useEffect(() => {
    const updatedContractDetails = {
      ...contractDetails,
      partner_margin_rate: calculateMarginRate(parseFloat(contractDetails.partner_margin_amount)),
      client_margin_rate: calculateMarginRate(parseFloat(contractDetails.client_margin_amount)),
      totalCommission: calculateTotalCommission(
        contractDetails.partner_margin_amount,
        contractDetails.client_margin_amount
      ),
      totalRate: calculateTotalRate(
        contractDetails.partner_margin_amount,
        contractDetails.client_margin_amount,
      ),
    };
    onSaveContractDetails(updatedContractDetails);
  }, [currentRate]);

  const calculateMarginRate = (amount) => {
    return currentRate !== 0 ? ((amount / currentRate) * 100).toFixed(2) : '0';
  };

  const calculateTotalCommission = (partnerAmount, clientAmount) => {
    return (parseFloat(partnerAmount) + parseFloat(clientAmount)).toFixed(2);
  };
  
  const calculateTotalRate = (partnerAmount, clientAmount) => {
    return (currentRate + parseFloat(partnerAmount) + parseFloat(clientAmount)).toFixed(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value) || 0;

    let updatedContractDetails = {
      ...contractDetails,
      [name]: parsedValue.toString(),
    };

    if (name === 'partner_margin_rate') {
      updatedContractDetails.partner_margin_amount = ((parsedValue / 100) * currentRate).toFixed(2);
    } else if (name === 'client_margin_rate') {
      updatedContractDetails.client_margin_amount = ((parsedValue / 100) * currentRate).toFixed(2);
    } else if (name === 'partner_margin_amount') {
      updatedContractDetails.partner_margin_rate = calculateMarginRate(parsedValue);
    } else if (name === 'client_margin_amount') {
      updatedContractDetails.client_margin_rate = calculateMarginRate(parsedValue);
    }

    updatedContractDetails.totalCommission = calculateTotalCommission(
      updatedContractDetails.partner_margin_amount,
      updatedContractDetails.client_margin_amount
    );
    updatedContractDetails.totalRate = calculateTotalRate(
      updatedContractDetails.partner_margin_amount,
      updatedContractDetails.client_margin_amount
    );

    onSaveContractDetails(updatedContractDetails);
  };

    return (
      <div className='full-width'>
        {context==='default'&&(
          <div className='card-component padding-fix width-fix'>
          <div className='header-xsmall'>Proposition commerciale</div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>TJ référent :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="rate"
                  className='input-normal'
                  value={currentRate}
                  onChange={(e) => setCurrentRate(parseFloat(e.target.value) || 0)}
                  aria-label="currentRate"
                  suffix="€ HT/jour"
                  max="10000"
                  disabled={!editing}
                />
              </div>
            </div>
            <div className="input-container-normal">
            <div className="input-container-normal">
              <div className='input-header-normal'>Disponibilité :</div>
              <div className='drawer-formfield-inactive'>
              <Input
                name="date_dispo"
                type="date"
                className='input-normal'
                value={localDateDispo}
                onChange={(e) => setLocalDateDispo(e.target.value)}
                placeholder='JJ/MM/AAAA'
                disabled={!editing}
              />
            </div>
            </div>
            </div>
          </div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>Commentaire :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="text"
                  name="comment"
                  className='input-normal'
                  value={localComment}
                  onChange={(e) => setLocalComment(e.target.value)}
                  aria-label="comment"
                  disabled={!editing}
                />
              </div>
            </div>
          </div>
        </div>
        )}
        
        {isAdmin &&(
          <div className='card-component padding-fix width-fix'>
          <div className='header-xsmall '>Commissions</div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>TJ référent :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="rate"
                  className='input-normal'
                  value={currentRate}
                  onChange={(e) => setCurrentRate(parseFloat(e.target.value) || 0)}
                  aria-label="currentRate"
                  suffix="€ HT/jour"
                  max="10000"
                />
              </div>
            </div>
            <div className="input-container-normal">
            </div>
          </div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>% commission partenaire :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="partner_margin_rate"
                  className='input-normal'
                  value={contractDetails.partner_margin_rate}
                  onChange={handleInputChange}
                  aria-label="partner_margin_rate"
                  max="200"
                  disabled={!editing}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>Montant commission partenaire :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="partner_margin_amount"
                  className='input-normal'
                  value={contractDetails.partner_margin_amount}
                  onChange={handleInputChange}
                  aria-label="partner_margin_amount"
                  suffix="€ HT/jour"
                  disabled={!editing}
                />
              </div>
            </div>
          </div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>% commission client :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="client_margin_rate"
                  className='input-normal'
                  value={contractDetails.client_margin_rate}
                  onChange={handleInputChange}
                  aria-label="client_margin_rate"
                  suffix="€ HT/jour"
                  max="200"
                  disabled={!editing}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>Montant commission client :</div>
              <div className='drawer-formfield-inactive'>
                <Input
                  type="number"
                  name="client_margin_amount"
                  className='input-normal'
                  value={contractDetails.client_margin_amount}
                  onChange={handleInputChange}
                  aria-label="client_margin_amount"
                  suffix="€ HT/jour"
                  disabled={!editing}
                />
              </div>
            </div>
          </div>
          <div className="inputs-row v-padding-fix">
            <div className="input-container-normal">
              <div className='input-header-normal'>Montant de la commission :</div>
              <div className='drawer-formfield-inactive'>{contractDetails.totalCommission} € HT/jour</div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>TJ client:</div>
              <div className='drawer-formfield-inactive'>{contractDetails.totalRate} € HT/jour</div>
            </div>
          </div>
        </div>
        )}
        
      </div>
    );
  };

  export default CardRfpApplicationProposal;
