import React from 'react';
import { Modal, Box } from '@mui/material';
import { Select } from './Select';
import { Button } from '../components/Button';
import { modalStyle } from '../utils/muiUtils';

const FilterModal = ({ open, handleClose, filters, filterOptions, handleFilterChange, resetFilters }) => {
    const renderSelects = () => {
        return filterOptions.map(option => (
            <div className='inputs-row'>
                <div className='input-container-normal'>
                    <div className='input-header-normal'>
                        {option.label}
                    </div>
                    <div className='input-content-normal'>
                        <Select
                            options={[{ value: '', label: option.defaultLabel }, ...option.options]}
                            value={filters[option.key]?.toString()}
                            onChange={(value) => handleFilterChange(option.key, value)}
                            sort={true}
                        />
                    </div>
                </div>
            </div>

        ));
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div className="dialog">
                    <div className="primitives">
                        <div className="text-wrapper">Filtres</div>
                        <div className="icon-button" onClick={handleClose}><img className="before-icon" src="/img/before-icon-2.svg" alt="Fermer" /></div>
                    </div>
                    <div className='full-width'>
                        {renderSelects()}
                    </div>
                    <div className="primitives-2">
                        <Button
                            appearance="secondary"
                            className="button-instance"
                            hasAfterIcon={false}
                            hasBeforeIcon={false}
                            hasDropdownIndicator={false}
                            intent="none"
                            label="Réinitialiser"
                            size="small"
                            stateProp="normal"
                            type="default"
                            onClick={resetFilters}
                            width='120px'
                        />
                        <Button
                            appearance="primary"
                            className="button-instance"
                            hasAfterIcon={false}
                            hasBeforeIcon={false}
                            hasDropdownIndicator={false}
                            intent="none"
                            label="Filtrer"
                            size="small"
                            stateProp="normal"
                            type="default"
                            onClick={handleClose}
                            width='120px'
                        />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default FilterModal;
