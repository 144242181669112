import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { CardClientHeader } from '../mycomponents/CardClientHeader';
import { CardPartnerStaff } from '../mycomponents/CardPartnerStaff/';
import { CardCompanyMarkets } from '../mycomponents/CardCompanyMarkets/CardCompanyMarkets';
import { CardPartnerBankInfo } from '../mycomponents/CardPartnerBankInfo';
import { CardPartnerAddress } from '../mycomponents/CardPartnerAddress';
import { CardPartnerInfo } from '../mycomponents/CardPartnerInfo';
import { CardPartnerMainContact } from '../mycomponents/CardPartnerMainContact';
import { mdiShoppingOutline } from '@mdi/js';
import { arraysEqual } from '../utils/dataTools';

const EditClientPage = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);
  const { id } = useParams(); // Get the client ID from URL params
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    vat_nbr: '',
    siren: '',
    rcs: '',
    legal_status: '',
    category: '',
    capital: 0,
    phone_number: '',
    web_site: '',
    addresses: '',
    billing_addr: '',
    bank_details: '',
    usemain: '',
    markets: {},
    rib: {
      bankName: '',
      accountnbr: '',
      iban: '',
      bic: '',
      key: '',
      bankid: ''
    }
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const memoizedAddress = useMemo(() => formData.addresses, [formData.addresses]);
  const memoizedBillingAddress = useMemo(() => formData.billing_addr, [formData.billing_addr]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await api.get(`/client/${id}`); // Fetch client data by ID
        setFormData(response.data); // Populate form fields with fetched client data
      } catch (error) {
        console.error('Error fetching client data:', error);
        setError(error.message);
      }
    };

    fetchClientData();
  }, [id]);

  const handleContactChange = useCallback(
    (updatedContact) => {
      setFormData(prevFormData => ({
        ...prevFormData,
        contact: updatedContact
      }));
    },
    []
  );

  const handleAddressChange = useCallback(
    (updateAddress) => {
      setFormData(prevFormData => ({
        ...prevFormData,
        addresses: updateAddress
      }));
    },
    []
  );

  const handleBillingAddressChange = useCallback(
    (updateAddress) => {
      setFormData(prevFormData => ({
        ...prevFormData,
        billing_addr: updateAddress
      }));
    },
    []
  );

  const handleSubmit = async (event) => {

    setLoading(true);
    setError(null);

    try {
      await api.put(`/client/${id}`, formData); // Update client data using PUT method
      //console.log('Client data updated successfully');
    } catch (error) {
      console.error('Error updating client data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [marketsData, setMarketsData] = useState(formData.markets);
  const saveMarkets = (updatedMarkets) => {
    if (!arraysEqual(marketsData, updatedMarkets)) {
      setMarketsData(updatedMarkets);
      setFormData(prevFormData => ({
        ...prevFormData,
        markets: updatedMarkets
      }));
      console.log('Markets updated');
    }
  };
  return (
    <div>
      <CardClientHeader rfp_title={formData.name} client_name={formData.name} client_ref="CLREF" eq_ref="EQREF" rfp_id={`${id}`} />
      {error && <div>Error: {error}</div>}
      <div className="columns-container">
        <div className="columnL">
          <div>
            <CardCompanyMarkets
              experiences={formData.markets}
              iconPath={mdiShoppingOutline}
              title="Marchés"
              placeHolder="Nouveau marché"
              editButton={true}
              enabled={false}
              context='editor'
              lCollapsed={false}
              saveMarkets={saveMarkets}
              handleSubmit={handleSubmit}
            />
          </div>
          <div>
            <CardPartnerMainContact
              title="Contact principal"
              contact={formData.contact}
              handleSubmit={handleSubmit}
              onContactChange={handleContactChange}
              editButton={true}
            />
          </div>
        </div>
        <div className="columnR">
          <div className="card-component">
            <CardPartnerStaff partner_name={formData.name} partner_id={id} type="client" />
          </div>
          <div>
            <CardPartnerInfo
              title="Informations générales"
              info={{
                name: formData.name,
                vat_nbr: formData.vat_nbr,
                siren: formData.siren,
                rcs: formData.rcs,
                legal_status: formData.legal_status,
                category: formData.category,
                capital: formData.capital,
                phone_number: formData.phone_number,
                web_site: formData.web_site
              }}
              handleSubmit={handleSubmit}
              onInfoChange={(updatedInfo) => {
                setFormData(prevFormData => ({
                  ...prevFormData,
                  ...updatedInfo
                }));
              }}
              editButton={true}
            />
          </div>
          <div>
            <CardPartnerAddress
              title="Adresse"
              address={memoizedAddress}
              handleSubmit={handleSubmit}
              onAddressChange={handleAddressChange}
              editButton={true}
            />
          </div>
          <div>
            <CardPartnerAddress
              title="Adresse de facturation"
              address={memoizedBillingAddress}
              handleSubmit={handleSubmit}
              onAddressChange={handleBillingAddressChange}
              editButton={true}
            />
          </div>
          <div>
            <CardPartnerBankInfo
              bankInfo={formData.rib}
              handleSubmit={handleSubmit}
              onBankInfoChange={(updatedBankInfo) =>
                setFormData(prevFormData => ({
                  ...prevFormData,
                  rib: updatedBankInfo
                }))
              }
              editButton={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClientPage;
