// RetainCandidate.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CardRfpTitle } from '../mycomponents/CardRfpTitle';
import { WizardItems } from "../components/WizardItems";
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import EditApplicant from '../mycomponents/EditApplicant';
import {CardRfpApplicationProposal} from '../mycomponents/CardRfpApplicationProposal';
import { useNavigate } from "react-router-dom";

const RetainCandidateFlow = () => {
    const [loading, setLoading] = useState(true); 
    const { rfp_id, application_id } = useParams();
    const auth = useAuth();
    const [wizardStep, setWizardStep] = useState(0);
    const [rfp, setRfp] = useState({});
    const [application, setApplication] = useState({});
    const [clientName, setClientName] = useState();
    const [contractDetails, setContractDetails] = useState( { partner_margin_rate: '0', client_margin_rate: '0', comment: '' });
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    const { userRoles } = useAuth();
    const isAdmin = userRoles.includes('admin');

    useEffect(() => {
        const fetchRfpDetails = async () => {
            try {
                setLoading(true); // Start loading
    
                const [rfpResponse, applicationResponse] = await Promise.all([
                    api.get(`/rfp/${rfp_id}`),
                    api.get(`/rfp/application/${application_id}`)
                ]);
    
                const rfpData = rfpResponse.data;
                const applicationData = applicationResponse.data;
                setRfp(rfpData);
                setApplication(applicationData);
                setContractDetails(applicationData.offer_details)

                //const applicantResponse = await api.get(`/applicant/${applicationData.applicant_id}`);
                //setApplicant(applicantResponse.data)
                
                if (rfpData.id_client) {
                    fetchClientName(rfpData.id_client);
                }
    
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // End loading
            }
        };
    
        if (auth.initialized) {
            fetchRfpDetails();
        }
    }, [auth.initialized, rfp_id, application_id]);

    const fetchClientName = async (client_id) => {
        try {
            const response = await api.get(`/client/${client_id}`);
            setClientName(response.data.name);
        } catch (error) {
            console.error('Error fetching client name:', error);
        }
    };

    const collectState = () => {
        return {
            application_id: application_id,
            rfp_id: rfp_id,
            
            //applicant: applicant,
            rfp: rfp,
            application: application,
            status: 0,
            client_name: '',
            client_id: rfp.id_client,
            prescriber: rfp.owner,
            mission_title: rfp.rfp_title,
            offer_type: rfp.offer_type,
            partner_name: '',  //TODO
            partner_id: application.partner_id,
            applicant_name: '',  //TODO
            applicant_id: application.applicant_id,
            start_date: rfp.rfp_start_date,
            end_date: rfp.rfp_end_date,
            opt_start_date: rfp.rfp_opt_start_date,
            opt_end_date:  rfp.rfp_opt_end_date,
            renewable: 1, //TODO
            location_primary: rfp.location_primary,
            location_secondary: rfp.location_secondary,
            remote: rfp.remote,
            rate: application.offer_details.rate,
            documents: {}, //TODO
            contracts: {},//TODO
            client_ref: rfp.client_ref,
            client_mkt: rfp.client_mkt,
            eq_ref: rfp.eq_ref

        };
    };

    const handleSubmit = async () => {
        /*
        const stateData = collectState();
        try {
           console.log(stateData);
            await api.post(`/mission`, stateData);
            //navigate(`/rfp/${id}`);
        } catch (error) {
            console.error('Error retaining candidate:', error);
        }
        */
        try {
            await api.put(`/rfp/application/${application_id}`, {
                ...application,
                status: "1",
                offer_details: contractDetails, // Make sure to send back updated contract details if needed
            });
            setApplication(application); // Update displayed application details

            // Update contractDetails with application.offer_type or a similar field if available
            setContractDetails(application.offer_details || { partner_margin_rate: '0', client_margin_rate: '0' });
            navigate(`/rfp/${rfp_id}`);

        } catch (error) {
            console.error('Error saving application details:', error);
        }
    };
   
    const handleApplicantUpdate = (updatedApplicant) => {
        //setApplicant(updatedApplicant);
        //setRate(updatedApplicant.rate);
    };
    const onSaveContractDetails = (updatedDetails) => {
        setContractDetails(updatedDetails);
        console.log(contractDetails);
    };

    const wizardStates = [
        ["Vérifiez et/ou complétez les informations sur le candidat", 0, "active", "inactive" ],
        ["Remplissez la commission", 1, "completed", "active"],
    ];

    const handlePrevNextStep = (step) => {
        setWizardStep(step);
    };

    if (loading) { // Render loading state
        return //<div className='center'><LogoEquity1 className="logo-equity" /></div>;
    }

    return (
        <div>
            <CardRfpTitle
                rfp_title={rfp.rfp_title}
                client_name={clientName}
                client_ref={rfp.client_ref}
                eq_ref="ref eq"
                rfp_id={`${rfp_id}`}
                isEditing={false}
                status={wizardStates[wizardStep][1]}
                updateStatus={handlePrevNextStep}
                finalize={handleSubmit}
                context='rc'
            />
            <div className='flex-cont'>
                <div className="wizard-cont">
                    <WizardItems
                        className="wizard-items-instance"
                        label="Information candidat"
                        overlapGroupClassName="design-component-instance-node"
                        state={wizardStates[wizardStep][2]}
                        type="initial"
                    />
                  
                    <WizardItems
                        className="wizard-items-2"
                        label="Commission"
                        overlapGroupClassName="wizard-items-4"
                        state={wizardStates[wizardStep][3]}
                        type="end"
                    />
                </div>
            </div>

            <div className='wizard-section-header'>
                {wizardStates[wizardStep][0]}
            </div>

            <div>
                { wizardStep === 0 && (
                    <EditApplicant id={application.applicant_id} onUpdateApplicant={handleApplicantUpdate} />
                )}
                {wizardStep === 1 && (    
                    <CardRfpApplicationProposal
                        rate={application.offer_details.rate}
                        contractDetails={application.offer_details}
                        onSaveContractDetails={onSaveContractDetails}
                        localComment=''
                        editing={true}
                        isAdmin={isAdmin}
                        context='rc'
                    />
                )}
            </div>
        </div>
        
    );
}

export default RetainCandidateFlow;
