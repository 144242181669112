import React, { useState, useCallback } from 'react';
import { Modal, Box } from '@mui/material';
import { Input } from "../components/Input";
import { Button } from '../components/Button';
import { modalStyle } from '../utils/muiUtils';

const CandidateFinalValidationModal = ({ open, handleClose, handleValidation }) => {
    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);
    
    const [formData, setFormData] = useState({
        status: '',
        application_id: '',
        rfp_id: '',
        start_date: '',
        po_number: '',
    });

    const validate=()=>{
        handleValidation();
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div className="dialog">
                    <div className="primitives">
                        <div className="text-wrapper">Validation définitive du candidat</div>
                        <div className="icon-button" onClick={handleClose}><img className="before-icon" src="/img/before-icon-2.svg" alt="Fermer" /></div>
                    </div>
                    <div className='inputs-row'>
                        <div className='input-container-normal'>
                            <div className='input-header-normal'>
                                N° bon de commande client
                            </div>
                            <div className='input-content-normal'>
                                <Input type="text" name="po_number" value={formData.po_number}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className='inputs-row'>
                        <div className='input-container-normal'>
                            <div className='input-header-normal'>
                                Date de démarrage définitive de la mission
                            </div>
                            <div className='input-content-normal'>
                                <Input type="date" name="start_date" value={formData.start_date} onChange={handleInputChange}
                                    placeholder="jj/mm/aaaa"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="primitives-2">
                        <Button
                            appearance="secondary"
                            className="button-instance"
                            hasAfterIcon={false}
                            hasBeforeIcon={false}
                            hasDropdownIndicator={false}
                            intent="none"
                            label="Annuler"
                            size="small"
                            stateProp="normal"
                            type="default"
                            onClick={handleClose}
                            width='120px'
                        />
                        <Button
                            appearance="primary"
                            className="button-instance"
                            hasAfterIcon={false}
                            hasBeforeIcon={false}
                            hasDropdownIndicator={false}
                            intent="positive"
                            label="Valider définitivement"
                            size="small"
                            stateProp="normal"
                            type="default"
                            onClick={validate}
                            width='140px'
                        />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default CandidateFinalValidationModal;
